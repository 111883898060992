import { useSwiper } from "swiper/react";
import rightArrow from "assets/right.png";

function SwiperButtonNext() {
  const swiper = useSwiper();
  return (
    <div className="loja-tems__arrow loja-tems__arrow-right">
      <img src={rightArrow} alt="" className="loja-tems__arrow-img" />
    </div>
  );
}

export default SwiperButtonNext;
