import AjudeTems from "./AjudeTems/AjudeTems";
import MainSlider from "pages/Home/MainSlider/MainSlider";
import MainSubjects from "pages/Home/Subject/MainSubjects";
import ProductsList from "components/ui/Product/ProductsList";
import ServicesList from "pages/Home/Service/ServicesList";
import Coordinators from "pages/Home/Coordinator/Coordinators";
import Colaboradores from "./Colaboradores/Colaboradores";
import { useEffect } from "react";


function HomePage() {
    useEffect(() => {
        document.title = 'TEMS | Templo Espiritual Maria Santíssima';
      }, []);

    return (
        <>
            <MainSlider />

            <AjudeTems />

            <MainSubjects />

            <Coordinators />

            <ServicesList />

            <ProductsList />
            
            <Colaboradores />
        </>
    );
}

export default HomePage;