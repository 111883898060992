import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import IconeSacolinhas from 'assets/icone-sacolinhas.jpg';

import SwiperSliderButtonPrev from "../../components/ui/Swiper/SwiperStoreButtonPrev";
import SwiperSliderButtonNext from "../../components/ui/Swiper/SwiperStoreButtonNext";

import { isMobile } from "react-device-detect";

export default function SacolinhasNatal() {
    return (
        <div className='sacolinhas'>
            <section className='sacolinhas__header'>
                <div className='sacolinhas__text-box'>
                    <h1 className='sacolinhas__title'>Doe o que você tem de melhor</h1>
                    <p className='sacolinhas__text'>Deixe uma criança feliz neste Natal. Faça parte, não fique à parte. Contribua e faça a diferença!</p>
                    <button className='sacolinhas__button' onClick={() => { window.location.href = 'https://ajude.tems.com.br/donations/sacolas-de-natal/' }}>Apadrinhar criança</button>
                </div>
            </section>

            <section className='sacolinhas__main'>
                <div style={{ width: '28rem' }}>
                    <h5 className="sacolinhas__main-text">
                        <span style={{ color: '#00710D', fontWeight: '800', fontSize: '5rem' }}>Ajude</span> a <span style={{ color: '#00710D', fontWeight: '800', fontSize: '5rem' }}>construir</span> um <span style={{ color: '#DE0000', fontWeight: '800', fontSize: '5rem' }}>mundo</span><img src={IconeSacolinhas} /><span style={{ color: '#F89600', fontWeight: '800', fontSize: '5rem', marginLeft: '1rem' }}>melhor</span>
                    </h5>
                    <button className="sacolinhas__main-button" onClick={() => { window.location.href = 'https://ajude.tems.com.br/donations/sacolas-de-natal/' }}>Colabore</button>
                </div>

                <div style={{ marginLeft: '7rem' }}>
                    <Swiper
                        modules={[Navigation, Autoplay, A11y]}
                        autoplay={{ delay: 5000, disableOnInteraction: false }}
                        spaceBetween={30}
                        slidesPerView={1}
                        loop={true}
                        navigation={{
                            nextEl: '.loja-tems__arrow-right',
                            prevEl: '.loja-tems__arrow-left',
                        }}
                    >
                        <SwiperSliderButtonPrev />
                        <SwiperSliderButtonNext />

                        <SwiperSlide>
                            <img className='algodao__sobre-slide' src='https://strapi-tems.s3.sa-east-1.amazonaws.com/FTS_SACOLINHA_NATAL_COLONIA_091218_690_1_1_05dd6ae4c7.jpg' />
                        </SwiperSlide>

                        <SwiperSlide>
                            <img className='algodao__sobre-slide' src='https://strapi-tems.s3.sa-east-1.amazonaws.com/FTS_SACOLINHA_NATAL_COLONIA_091218_190_1_3361f94fe8.jpg' />
                        </SwiperSlide>

                        <SwiperSlide>
                            <img className='algodao__sobre-slide' src='https://strapi-tems.s3.sa-east-1.amazonaws.com/entrega_de_sacolinhas_de_natal_15_1068x580_1_bc912bec52.jpg' />
                        </SwiperSlide>

                        <SwiperSlide>
                            <img className='algodao__sobre-slide' src='https://strapi-tems.s3.sa-east-1.amazonaws.com/5_24528_ce90b4d54e.jpg' />
                        </SwiperSlide>

                    </Swiper>
                </div>
            </section>

            <section className="sacolinhas__cidades">
                <h3 className="sacolinhas__cidades-title">Confira as cidades com distribuição<br />de Sacolinhas e Cestas de Natal</h3>


                {isMobile ? (
                    <ul className="sacolinhas__cidades-mobile-list">
                        <li className="sacolinhas__cidades-mobile">
                            <p className="sacolinhas__cidades-mobile-cidade">São Luís/MA</p>
                            <p><span style={{ fontWeight: '600' }}>Data:</span> 9 de dezembro, às 15h</p>
                            <p><span style={{ fontWeight: '600' }}>Endereço:</span> Grupo Solidariedade e Vida Casa Acolher</p>
                            <p><span style={{ fontWeight: '600' }}>Contato:</span> (98) 9 9193-4690 (98) 9 9211-1966</p>
                        </li>
                        <li className="sacolinhas__cidades-mobile">
                            <p className="sacolinhas__cidades-mobile-cidade">São Paulo/SP</p>
                            <p><span style={{ fontWeight: '600' }}>Data:</span> 10 de dezembro, às 9h</p>
                            <p><span style={{ fontWeight: '600' }}>Endereço:</span> Rua Jaraguá, 371 - Bom Retiro</p>
                            <p><span style={{ fontWeight: '600' }}>Contato:</span> (11) 3354-1500</p>
                        </li>
                        <li className="sacolinhas__cidades-mobile">
                            <p className="sacolinhas__cidades-mobile-cidade">São José/SC</p>
                            <p><span style={{ fontWeight: '600' }}>Data:</span> 12 de dezembro, às 11h</p>
                            <p><span style={{ fontWeight: '600' }}>Endereço:</span>Av. Cel. Antônio Lehmkhul, 1036 - 1076, Águas Mornas/SC (Pastoral de Ação Social aos Necessitados)</p>
                            <p><span style={{ fontWeight: '600' }}>Contato:</span> (48) 9 9606-2890</p>
                        </li>
                        <li className="sacolinhas__cidades-mobile">
                            <p className="sacolinhas__cidades-mobile-cidade">Limoeiro do Norte/CE (Jaguaretama/CE)</p>
                            <p><span style={{ fontWeight: '600' }}>Data:</span> 10 de dezembro, às 9h</p>
                            <p><span style={{ fontWeight: '600' }}>Endereço:</span>CE-368, Jaguaretama/CE</p>
                            <p><span style={{ fontWeight: '600' }}>Contato:</span>(88) 9 9627-6025 (88) 9 9652-9229</p>
                        </li>
                        <li className="sacolinhas__cidades-mobile">
                            <p className="sacolinhas__cidades-mobile-cidade">Caxias do Sul/RS</p>
                            <p><span style={{ fontWeight: '600' }}>Data:</span> 16 de dezembro, às 14h</p>
                            <p><span style={{ fontWeight: '600' }}>Endereço:</span> Rua São Francisco de Paula, 238 - Kayser</p>
                            <p><span style={{ fontWeight: '600' }}>Contato:</span> (54) 3211-0314</p>
                        </li>
                        <li className="sacolinhas__cidades-mobile">
                            <p className="sacolinhas__cidades-mobile-cidade">Manaus/AM</p>
                            <p><span style={{ fontWeight: '600' }}>Data:</span> 16 de dezembro, às 9h</p>
                            <p><span style={{ fontWeight: '600' }}>Endereço:</span> Rua Alberto Campainha, 57 - Colônia Antônio Aleixo (Lar de Scheilla)</p>
                            <p><span style={{ fontWeight: '600' }}>Contato:</span> (92) 9 9441-2605 (92) 9 9280-1767</p>
                        </li>
                        <li className="sacolinhas__cidades-mobile">
                            <p className="sacolinhas__cidades-mobile-cidade">Canoas/RS</p>
                            <p><span style={{ fontWeight: '600' }}>Data:</span> 16 de dezembro, às 15h</p>
                            <p><span style={{ fontWeight: '600' }}>Endereço:</span> Rua Gonçalves Dias, 66, Sala 403 - Centro</p>
                            <p><span style={{ fontWeight: '600' }}>Contato:</span>(51) 9256-8410</p>
                        </li>
                        <li className="sacolinhas__cidades-mobile">
                            <p className="sacolinhas__cidades-mobile-cidade">Goiânia/GO</p>
                            <p><span style={{ fontWeight: '600' }}>Data:</span> 16 de dezembro, às 8h</p>
                            <p><span style={{ fontWeight: '600' }}>Endereço:</span>Rua H 91, QD 206, LT 34/35 - Aparecida de Goiânia/GO</p>
                            <p><span style={{ fontWeight: '600' }}>Contato:</span>(62) 8148-2907</p>
                        </li>
                        <li className="sacolinhas__cidades-mobile">
                            <p className="sacolinhas__cidades-mobile-cidade">Belo Horizonte/MG</p>
                            <p><span style={{ fontWeight: '600' }}>Data:</span> 17 de dezembro, às 9h</p>
                            <p><span style={{ fontWeight: '600' }}>Endereço:</span> Rua Dr. Álvaro Camargos, 450 São João Batista</p>
                            <p><span style={{ fontWeight: '600' }}>Contato:</span> (31) 8471-3389 (31) 8830-8401</p>
                        </li>

                        <li className="sacolinhas__cidades-mobile">
                            <p className="sacolinhas__cidades-mobile-cidade">Fortaleza/CE</p>
                            <p><span style={{ fontWeight: '600' }}>Data:</span> 16 de dezembro, às 9h</p>
                            <p><span style={{ fontWeight: '600' }}>Endereço:</span>Rua Trajano Alves de Aguiar, 100 - Cidade dos Funcionários</p>
                            <p><span style={{ fontWeight: '600' }}>Contato:</span>(85) 8722-7335</p>
                        </li>
                        <li className="sacolinhas__cidades-mobile">
                            <p className="sacolinhas__cidades-mobile-cidade">Santuário Sara Kali</p>
                            <p><span style={{ fontWeight: '600' }}>Data:</span> 17 de dezembro, às 9h</p>
                            <p><span style={{ fontWeight: '600' }}>Endereço:</span> Estrada Municipal Joaquim Rezende Filho - Bairro dos Ferreiras</p>
                            <p><span style={{ fontWeight: '600' }}>Contato:</span> (35) 9 9929-2435</p>
                        </li>
                        <li className="sacolinhas__cidades-mobile">
                            <p className="sacolinhas__cidades-mobile-cidade">Maringá/PR</p>
                            <p><span style={{ fontWeight: '600' }}>Data:</span> 17 de dezembro, às 9h</p>
                            <p><span style={{ fontWeight: '600' }}>Endereço:</span> Rua Dr. Naby Zacarias, 262 - Vila Progresso</p>
                            <p><span style={{ fontWeight: '600' }}>Contato:</span> (44) 9 9876-5750</p>
                        </li>
                        <li className="sacolinhas__cidades-mobile">
                            <p className="sacolinhas__cidades-mobile-cidade">Ribeirão Preto/SP</p>
                            <p><span style={{ fontWeight: '600' }}>Data:</span> 21 de dezembro, às 11h30</p>
                            <p><span style={{ fontWeight: '600' }}>Endereço:</span>Rua Appa, 500 - Vila Monte Alegre - Ribeirão Preto/SP</p>
                            <p><span style={{ fontWeight: '600' }}>Contato:</span>(16) 3979-0229</p>
                        </li>
                        <li className="sacolinhas__cidades-mobile">
                            <p className="sacolinhas__cidades-mobile-cidade">Juiz de Fora/MG</p>
                            <p><span style={{ fontWeight: '600' }}>Data:</span> 23 e 24 de Dezembro, às 15h</p>
                            <p><span style={{ fontWeight: '600' }}>Endereço:</span> Av. dos Andradas, 1069 - Morro da Glória</p>
                            <p><span style={{ fontWeight: '600' }}>Contato:</span> (22) 9 9702-8031</p>
                        </li>
                        <li className="sacolinhas__cidades-mobile">
                            <p className="sacolinhas__cidades-mobile-cidade">Caxias do Sul/RS</p>
                            <p><span style={{ fontWeight: '600' }}>Data:</span> 16 e 24 de dezembro, às 14h</p>
                            <p><span style={{ fontWeight: '600' }}>Endereço:</span>Rua São Francisco de Paula, 238 - Bairro Kayser - Caxias do Sul/RS</p>
                            <p><span style={{ fontWeight: '600' }}>Contato:</span>(54) 3211-0014</p>
                        </li>


                    </ul>
                ) : <table className='hoteis__table'>
                    <tr>
                        <th>Cidade</th>
                        <th>Data</th>
                        <th>Endereço</th>
                        <th>Contato</th>
                    </tr>
                    <tr>
                        <td>São Luís/MA</td>
                        <td>9 de dezembro, às 15h</td>
                        <td>Grupo Solidariedade e Vida Casa Acolher</td>
                        <td>(98) 9 9193-4690<br/>(98) 9 9211-1966</td>
                    </tr>
                    <tr>
                        <td>São Paulo/SP</td>
                        <td>10 de dezembro, às 9h</td>
                        <td>Rua Jaraguá, 371 - Bom Retiro</td>
                        <td>(11) 3354-1500</td>
                    </tr>
                    <tr>
                        <td>São José/SC</td>
                        <td>12 de dezembro, às 11h</td>
                        <td>Av. Cel. Antônio Lehmkhul, 1036 - 1076, Águas Mornas/SC (Pastoral de Ação Social aos Necessitados)</td>
                        <td>(48) 9 9606-2890</td>
                    </tr>
                    <tr>
                        <td>Limoeiro do Norte/CE (Jaguaretama/CE)</td>
                        <td>10 de dezembro, às 9h</td>
                        <td>CE-368, Jaguaretama/CE</td>
                        <td>(88) 9 9627-6025<br/>(88) 9 9652-9229</td>
                    </tr>
                    <tr>
                        <td>Caxias do Sul/RS</td>
                        <td>16 de dezembro, às 14h</td>
                        <td>Rua São Francisco de Paula, 238 - Kayser</td>
                        <td>(54) 3211-0314</td>
                    </tr>
                    <tr>
                        <td>Manaus/AM</td>
                        <td>16 de dezembro, às 9h</td>
                        <td>Rua Alberto Campainha,<br/>57 - Colônia Antônio Aleixo (Lar de Scheilla)</td>
                        <td>(92) 9 9441-2605<br/>(92) 9 9280-1767</td>
                    </tr>
                    <tr>
                        <td>Canoas/RS</td>
                        <td>16 de dezembro, às 15h</td>
                        <td>Rua Gonçalves Dias, 66, Sala 403 - Centro</td>
                        <td>(51) 9256-8410</td>
                    </tr>
                    <tr>
                        <td>Goiânia/GO</td>
                        <td>16 de dezembro, às 8h</td>
                        <td>Rua H 91, QD 206, LT 34/35 - Aparecida de Goiânia/GO</td>
                        <td>(62) 8148-2907</td>
                    </tr>
                    <tr>
                        <td>Belo Horizonte/MG</td>
                        <td>17 de dezembro, às 9h</td>
                        <td>Rua Dr. Álvaro Camargos, 450 São João Batista</td>
                        <td>(31) 8471-3389<br/>(31) 8830-8401</td>
                    </tr>
                    <tr>
                        <td>Fortaleza/CE</td>
                        <td>16 de dezembro, às 9h</td>
                        <td>Rua Trajano Alves de Aguiar,<br/>100 - Cidade dos Funcionários</td>
                        <td>(85) 8722-7335</td>
                    </tr>
                    <tr>
                        <td>Santuário Sara Kali</td>
                        <td>17 de dezembro, às 9h</td>
                        <td>Estrada Municipal Joaquim Rezende Filho<br/>- Bairro dos Ferreiras</td>
                        <td>(35) 9 9929-2435</td>
                    </tr>
                    <tr>
                        <td>Maringá/PR</td>
                        <td>17 de dezembro, às 9h</td>
                        <td>Rua Dr. Naby Zacarias, 262 - Vila Progresso</td>
                        <td>(44) 9 9876-5750</td>
                    </tr>
                    <tr>
                        <td>Ribeirão Preto/SP</td>
                        <td>21 de dezembro, às 11h30</td>
                        <td>Rua Appa, 500 - Vila Monte Alegre - Ribeirão Preto/SP</td>
                        <td>(16) 3979-0229</td>
                    </tr>
                    <tr>
                        <td>Juiz de Fora/MG</td>
                        <td>23 e 24 de Dezembro, às 15h</td>
                        <td>Av. dos Andradas, 1069 - Morro da Glória</td>
                        <td>(22) 9 9702-8031</td>
                    </tr>
                    <tr>
                        <td>Caxias do Sul/RS</td>
                        <td>16 e 24 de dezembro, às 14h</td>
                        <td>Rua São Francisco de Paula, 238 - Bairro Kayser - Caxias do Sul/RS</td>
                        <td>(54) 3211-0014</td>
                    </tr>



                </table>
                }


            </section>

        </div>
    )
}
