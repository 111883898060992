import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const BASE_URL = process.env.REACT_APP_STRAPI_URL + '/api';

const caravanasApi = createApi({
    reducerPath: 'caravanas',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}`,
        prepareHeaders: (headers) => {
            headers.set("Authorization", process.env.REACT_APP_STRAPI_TOKEN_API_KEY);
            return headers;
        }
    }),
    endpoints(builder) {
        return {
            fetchCaravanas: builder.query({
                query: () => {
                    return {
                        url: "caravana-de-luzs?populate=*&pagination[limit]=100",
                        method: "GET",
                    };
                }
            }),
            fetchCaravanasById: builder.query({
                query: id => {
                    return {
                        url: `caravana-de-luzs/${id}?populate=*`,
                        method: "GET"
                    }
                }
            })
        }
    }
});


export const {
    useFetchCaravanasQuery,
    useFetchCaravanasByIdQuery
} = caravanasApi;
export { caravanasApi };