function ProductItem({ name, permalink, images }) {
    return (
        <a href={permalink} className="loja-tems__link">
            <div className="loja-tems__item-box">
                <img className="loja-tems__img" src={images[0].src} alt={name} />
                <p className="loja-tems__title">{name}</p>
            </div>
        </a>
    );
}

export default ProductItem;