import imgGilberto from 'assets/gilberto.png';
import imgAdolfo from 'assets/adolfo.png';
import { motion } from 'framer-motion';

function Coordinators() {

    const isMobile = window.innerWidth < 768;

    const attributes = !isMobile ? {
        initial: { opacity: 0, x: '-100' },
        whileInView: { opacity: 1, x: 0 },
        transition: { duration: 1 }
    } : '';

    return (
        <div className="coordenadores">
            <motion.figure
                {...attributes}
                className='coordenadores__box'>
                <img src={imgGilberto} alt='Imagem Gilberto Rissato' className='coordenadores__img' />
                <figcaption className='coordenadores__text-box'>
                    <h6 className='coordenadores__name'>Gilberto Rissato</h6>
                    <p className='coordenadores__role'>Fundador do TEMS</p>
                </figcaption>
            </motion.figure>


            <motion.figure
                {...attributes}
                className='coordenadores__box'>
                <img src={imgAdolfo} alt='Imagem Adolfo Schultz' className='coordenadores__img' />
                <figcaption className='coordenadores__text-box'>
                    <h6 className='coordenadores__name'>Adolfo Schultz</h6>
                    <p className='coordenadores__role'>Mentor Espiritual do TEMS</p>
                </figcaption>
            </motion.figure>
        </div>
    );
}

export default Coordinators;