import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const BASE_URL = 'https://temsonline.com.br/wp-json/wc/v3/products?category=361';

const produtosApi = createApi({
    reducerPath: 'produtos',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}`
    }),
    endpoints(builder) {
        return {
            fetchProducts: builder.query({
                query: () => {
                    return {
                        url: 'products',
                        params: {
                            category: '22',
                            consumer_key: process.env.REACT_APP_TEMSONLINE_CONSUMER_KEY,
                            consumer_secret: process.env.REACT_APP_TEMSONLINE_CONSUMER_SECRET,
                        },
                        method: 'GET'
                    }
                }
            })
        }
    }
})

export const { useFetchProductsQuery } = produtosApi;
export { produtosApi };