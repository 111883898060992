import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y, Autoplay } from "swiper";
import SwiperStoreButtonPrev from "../../components/ui/Swiper/SwiperStoreButtonPrev";
import SwiperStoreButtonNext from "../../components/ui/Swiper/SwiperStoreButtonNext";
import HandsBackground from "../../assets/hands_background.png";
import { useFetchPrecesQuery } from "store";
import { Link } from "react-router-dom";
import { useEffect } from "react";

function PrecePage() {

    const { data } = useFetchPrecesQuery();

    let content;
    
    useEffect(() => {
        document.title = 'Preces';
    }, []);

    if (data) {
        let oracoes = data.data.filter(item => item.attributes.tipo === 'oracao');
        let preces = data.data.filter(item => item.attributes.tipo === 'prece');

        content = (
            <div className="preces">
                <section>
                    <h4 className="preces__title">Orações</h4>
                    <div className="preces__swiper-box">
                        <Swiper
                            modules={[Navigation, A11y, Autoplay]}
                            autoplay={{ delay: 4000, disableOnInteraction: false }}
                            loop={true}
                            slidesPerView={4}
                            breakpoints={{
                                300: {
                                  slidesPerView: 1
                                },
                                1200: {
                                    slidesPerView: 4
                                }
                              }}
                            spaceBetween={40}
                            navigation={{
                                nextEl: '.loja-tems__arrow-right',
                                prevEl: '.loja-tems__arrow-left',
                            }}

                        >
                            {oracoes.map((item, index) => {
                                let oracao = item.attributes;
                                return (
                                    <SwiperSlide key={item.id}>
                                        <Link to={`/preces/${item.id}`}>
                                            <figure className="preces__item">
                                                <img src={oracao.img.data.attributes.url} />
                                                <figcaption>
                                                    {oracao.titulo}
                                                </figcaption>
                                            </figure>
                                        </Link>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                        <SwiperStoreButtonPrev />
                        <SwiperStoreButtonNext />
                    </div>
                </section>

                <div 
                className="preces__background"
                style={{
                    backgroundColor: '#FFF6E7',
                    backgroundImage: "url(" + HandsBackground + ")",
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '25%',
                    backgroundPosition: '90% 50%',
                }}>
                    <section>
                        <h4 className="preces__title">Preces</h4>

                        <ul className="preces__list">
                            {
                                preces.map((item, index) => {
                                    let prece = item.attributes;
                                    return (
                                        <li key={item.id} style={{ width: 'fit-content' }}>
                                            <Link to={`/preces/${item.id}`}>
                                                <figure className="preces__item">
                                                    <img src={prece.img.data.attributes.url} />
                                                    <figcaption>
                                                        {prece.titulo}
                                                    </figcaption>
                                                </figure>
                                            </Link>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </section>
                </div>

                <section className="preces__luzes-divinas">
                    <h4 className="preces__title">Coletânea de Preces</h4>
                    <p>Transforme cada momento em reflexão e paz
                        com nossa coletânea "Luzes Divinas".
                        Adquira agora e fortaleça sua espiritualidade!</p>
                    <a href="https://temsonline.com.br/produto/coletanea-de-oracoes-luzes-divinas/" target="_blank" className="preces__button">Adquirir</a>
                </section>

            </div>
        );
    }
    return content;
}

export default PrecePage;