import SearchIcon from 'assets/search-2.png';
import CaravanaList from './CaravanaList';
import { useFetchCaravanasQuery } from 'store';
import { useEffect, useState, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import FilterIcon from 'assets/filter-icon.png';
import FilterIconWhite from 'assets/filter-icon-white.png';

function CaravanasDeLuzPage() {

    const { data } = useFetchCaravanasQuery();
    const [caravanas, setCaravanas] = useState([]);
    const [filterActive, setFilterActive] = useState(false);
    const resultRef = useRef(null);

    useEffect(() => {
        document.title = 'Caravanas de Luz';
    }, []);

    useEffect( () => {
        if(!filterActive && data){
            if(caravanas.length == 0){
                cidadesFiltro('Ver todos')
            }
        }
    }, [filterActive, data])

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (data) {
            const tags = document.querySelectorAll('.caravanas__filter-cidade-tag')

            tags.forEach(tag => {
                if (tag.textContent === 'Ver todos') {
                    tag.click()
                }
            });
        }
    }, [data])


    let content = '';

    const estados = ['Ver todos', 'Acre', 'Alagoas', 'Amapá', 'Amazonas', 'Bahia', 'Ceará', 'Distrito Federal', 'Espírito Santo', 'Goiás', 'Maranhão', 'Mato Grosso', 'Mato Grosso do Sul', 'Minas Gerais', 'Pará', 'Paraíba', 'Paraná', 'Pernambuco', 'Piauí', 'Rio de Janeiro', 'Rio Grande do Norte', 'Rio Grande do Sul', 'Rondônia', 'Roraima', 'Santa Catarina', 'São Paulo', 'Sergipe', 'Tocantins'];

    const cidadesFiltro = (estadoEscolhido, event) => {
        clear();
        if(event){
            const el = event.target;
            el.classList.add('caravanas__filter-cidade-tag-selected');
        }
        let caravanasFiltered;

        if (estadoEscolhido !== 'Ver todos') {
            caravanasFiltered = [...data.data.filter((caravana) => {
                return caravana.attributes.estado === estadoEscolhido;
            })].sort((a, b) => new Date(a.attributes.data_atendimento + 'T00:00:00') - new Date(b.attributes.data_atendimento + 'T00:00:00'));
        } else {
            caravanasFiltered = [...data.data].sort((a, b) => new Date(a.attributes.data_atendimento + 'T00:00:00') - new Date(b.attributes.data_atendimento + 'T00:00:00'))
        }

        setCaravanas(caravanasFiltered);
    }

    const clear = () => {
        const searchInput = document.getElementById('search-input');
        searchInput.value = '';

        const tags = document.querySelectorAll('.caravanas__filter-cidade-tag')

        tags.forEach(tag => {
            if (tag.classList.contains('caravanas__filter-cidade-tag-selected')) {
                tag.classList.remove('caravanas__filter-cidade-tag-selected')
            }
        });
    }

    const handleSearchChange = (event) => {
        if (event.target.value.length > 2) {
            const caravanasFiltered = data.data.filter(caravana =>
            (
                caravana.attributes.nome.toLowerCase().includes(event.target.value.toLowerCase()) ||
                caravana.attributes.estado.toLowerCase().includes(event.target.value.toLowerCase())
            ));
            setCaravanas(caravanasFiltered);
        } else {
            setCaravanas(data.data[0]);
        }
    }


    if (data) {
        content = <CaravanaList data={caravanas} resultRef={resultRef} />
    }

    return (
        <div className="caravanas">
            <div className="caravanas__header">
                <h4 className="caravanas__titulo" ref={resultRef}>Caravanas de Luz</h4>
                <div className="caravanas__search-box">
                    <input type="text" id="search-input" placeholder="Buscar" onChange={handleSearchChange} />
                    <div className="caravanas__search-icon"><img src={SearchIcon} alt='Botão pesquisar' /></div>
                </div>
            </div>

            <main>

                {isMobile && <button
                    onClick={() => setFilterActive(!filterActive)}
                    className={filterActive ?
                        'caravanas__filter-button' :
                        'caravanas__filter-button caravanas__filter-button-active'
                    }>
                    <img src={filterActive ? FilterIcon : FilterIconWhite} />&nbsp;&nbsp;Filtro</button>}

                {((isMobile && filterActive) || (!isMobile)) && 
                <div className="caravanas__filter">
                    <button className="caravanas__filter-clear-button" onClick={() => {
                        clear();
                        const tags = document.querySelectorAll('.caravanas__filter-cidade-tag');
                        tags.forEach(tag => {
                            if (tag.textContent === 'Ver todos') {
                                tag.click()
                            }
                        })
                    }}>Limpar</button>

                    <p className="caravanas__filter-estados-text" >Estados</p>

                    {estados.map(estado => <span onClick={(event) => cidadesFiltro(estado, event)} className="caravanas__filter-cidade-tag" key={estado}>{estado}</span>)}

                </div>
                }

                <div className="caravanas__list-box">
                    <p className="caravanas__text">Todas as próximas Caravanas de Luz</p>
                    {content}
                </div>
            </main>
        </div>
    );
}

export default CaravanasDeLuzPage;