import React from 'react'

import Santuario from 'assets/santuario.png';
import ICE from 'assets/ice.png';
import Heras from 'assets/heras.png';
import Aguia from 'assets/aguia.png';

export default function Colaboradores() {
    return (
        <ul className="colaboradores__list">
            <li className="colaboradores__item">
                <img className="colaboradores__img" src={Santuario} alt="Logo Santuário Sara Kali" />
            </li>
            <li className="colaboradores__item">
                <img className="colaboradores__img" src={ICE} alt="Logo Instituto Colônia Esperança" />
            </li>
            <li className="colaboradores__item">
                <img className="colaboradores__img" src={Heras} alt="Logo Editora Heras" />
            </li>
            <li className="colaboradores__item">
                <img className="colaboradores__img" src={Aguia} alt="Logo Gráfica Águia" />
            </li>
        </ul>
    )
}
