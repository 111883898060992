import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const BASE_URL = process.env.REACT_APP_STRAPI_URL + '/api';

const rituaisTemsApi = createApi({
    reducerPath: 'rituais',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}`,
        prepareHeaders: headers => {
            headers.set("Authorization", process.env.REACT_APP_STRAPI_TOKEN_API_KEY);
            return headers;
        }
    }),
    endpoints(builder) {
        return {
            fetchRituais: builder.query({
                query: () => {
                    return {
                        url: "rituais-do-mes?populate=*",
                        method: "GET"
                    };
                }
            }),
            fetchRituaisById: builder.query({
                query: id => {
                    return {
                        url: `rituais-do-mes/${id}?populate=*`,
                        method: "GET"
                    }
                }
            }),
            fetchVelas: builder.query({
                query: () => {
                    return {
                        url: "velas?populate=*",
                        method: "GET"
                    };
                }
            }) 
        }
    }
});


export const {
    useFetchRituaisQuery,
    useFetchRituaisByIdQuery,
    useFetchVelasQuery
} = rituaisTemsApi;
export { rituaisTemsApi };