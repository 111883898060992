import { useSwiper } from "swiper/react";
import leftArrow from "assets/left.png";

function SwiperButtonPrev() {
  const swiper = useSwiper();
  return (
    <div className="loja-tems__arrow loja-tems__arrow-left">
      <img src={leftArrow} alt="" className="loja-tems__arrow-img" />
    </div>
  );
}

export default SwiperButtonPrev;
