import React, { useState } from 'react'
import CaravanaItem from '../CaravanaItem';

export default function CaravanaList({ data, resultRef }) {

    const [itemsToShow, setItemsToShow] = useState(5);

    const showmore = () => {
        setItemsToShow(data.length)
    }

    const showless = () => {
        setItemsToShow(5)
        resultRef.current.scrollIntoView({ behavior: "smooth" });
    }

    let content = '';

    if (data.length > 0) {
        content = <>
            {
                data.slice(0, itemsToShow).map(caravana => {
                    return (
                        <CaravanaItem key={caravana.id} caravana={caravana} />
                    );
                })
            }

            {data.length > 4 && (itemsToShow == data.length ? <button className='caravanas__length-button' onClick={showless}>mostrar menos</button> : <button className='caravanas__length-button' onClick={showmore}>Carregar mais Caravanas</button>)}
        </>
    }


    return (
        <ul ref={resultRef} className="caravanas__list">
            {content}
        </ul>
    )
}
