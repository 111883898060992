import { useFetchNewsQuery } from "../../../store/apis/ultimasNoticiasApi";
import { Oval } from "react-loader-spinner";
import CarouselList from '../../../components/ui/Carousel/CarouselList';

function MainSlider() {
  const { data, error, isFetching } = useFetchNewsQuery();

  let content;
  if (isFetching || error) {
    content = (
      <div style={{ width: '100vw', height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Oval
          height={80}
          width={80}
          color="#002a89"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="lightblue"
          strokeWidth={2}
          strokeWidthSecondary={2}

        />
      </div>
    );
  } else {
    content = (
      <>
        <CarouselList data={data.data[0]}/>
      </>
    )
  }

  return <div className="main-slider">{content}</div>;
}

export default MainSlider;
