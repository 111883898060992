import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_STRAPI_URL + '/api';

const ultimasNoticiasApi = createApi({
  reducerPath: "noticias",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
    prepareHeaders: (headers) => {
      headers.set("Authorization", process.env.REACT_APP_STRAPI_TOKEN_API_KEY);
      return headers;
    }
  }),
  endpoints(builder) {
    return {
      fetchNews: builder.query({
        query: () => {
          return {
            url: "destaques?filters[nome][$eqi]=Destaque%20Site%20TEMS&populate[noticias][populate]=*&populate[ritual_do_mes][populate]=*&populate[caravana_de_luzs][populate]=*",
            method: "GET",
          };
        },
      }),
      fetchRitualsNews: builder.query({
        query:  () => {
          return {
            url: "destaques?filters[nome][$eqi]=Destaque%20Ritual%20do%20Mes&populate[ritual_do_mes][populate]=*",
            method: "GET",
          };
        },
      })
    };
  },
});

export const { useFetchNewsQuery, useFetchRitualsNewsQuery } = ultimasNoticiasApi;
export { ultimasNoticiasApi };
