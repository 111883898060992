import { useFetchDonationPercentageQuery } from 'store';
import background from 'assets/ret_background.png';
import campanhaImg from 'assets/campanha_ajude_sede.png';
import gilbertoImg from 'assets/gilberto_icone.png';
import { Oval } from 'react-loader-spinner';
import { isMobile } from 'react-device-detect';

function AjudeTems() {

    const { data, error, isFetching } = useFetchDonationPercentageQuery();

    let content;

    if (isFetching) {
        content = (
            <div style={{ width: '90vw', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3rem' }}>
                <Oval
                    height={80}
                    width={80}
                    color="#002a89"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="lightblue"
                    strokeWidth={2}
                    strokeWidthSecondary={2}

                />
            </div>
        );
    } else if (error) {
        content = <div>Nao foi possivel buscar os dados...</div>;
    } else {

        const goal = parseFloat(data.forms[0].goal.amount);
        const currentValue = parseFloat(data.forms[0].goal.percentage_completed);

        if (!isMobile) {
            content = (
                <>
                    <div className="ajude-tems__title-box">
                        <img src={background} alt="" className="ajude-tems__title-background" />
                        <p className="ajude-tems__title">Campanha<br />Nosso Sonho</p>
                    </div>

                    <div className="ajude-tems__input-box">
                        <p className="ajude-tems__text">Ajude a realizar nosso sonho!</p>
                        <p className='ajude-tems__current-value'>{currentValue}%</p>
                        <input className="ajude-tems__input" type="range" min="0" max={goal} value={currentValue} readOnly />
                    </div>

                    <button className="ajude-tems__button" onClick={() => { window.location.href = 'https://ajude.tems.com.br/donations/nosso-sonho/' }}>Participe</button>
                    <img src={gilbertoImg} alt="Desenho do Gilberto" className='ajude-tems__gilberto-icone' />
                    <img className="ajude-tems__ajude-img" src={campanhaImg} alt="" />
                </>
            );
        } else {
            content = (
                <>
                    <div className="ajude-tems-mobile__title-box">
                        <p className="ajude-tems-mobile__title">Campanha Nosso Sonho</p>
                    </div>

                    <div className='ajude-tems-mobile__input-img-box'>
                        <div className="ajude-tems-mobile__input-box">
                            <p className="ajude-tems-mobile__text">Ajude a realizar nosso sonho!</p>
                            <p className='ajude-tems-mobile__current-value'>{currentValue}%</p>
                            <input className="ajude-tems-mobile__input" type="range" min="0" max={goal} value={currentValue} readOnly />
                            <button className="ajude-tems-mobile__button" onClick={() => { window.location.href = 'https://ajude.tems.com.br/donations/nosso-sonho/' }}>Participe</button>
                        </div>

                        <img src={gilbertoImg} alt="Desenho do Gilberto" className='ajude-tems-mobile__gilberto-icone' />
                        <img className="ajude-tems-mobile__ajude-img" src={campanhaImg} alt="" />
                    </div>
                </>
            );
        }
    }

    return (
        <div className="ajude-tems">
            {content}
        </div>
    );
}

export default AjudeTems;