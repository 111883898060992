import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useParams } from "react-router-dom"
import { useFetchRituaisByIdQuery } from "store";
import { useEffect } from "react";


export default function Ritual() {

    const { id } = useParams();
    const { data } = useFetchRituaisByIdQuery(id);

    useEffect(() => {
        if(data) document.title = data.data.attributes.nome;
    }, [data]);
    
    let content;
    
    if (data) {
        let ritual = data.data.attributes;
        let background = ritual.img.data.attributes.url

        content = (
            <main className="ritual-mes">
                <section className="ritual-mes__header" style={{ backgroundImage: `url(${background})` }}>
                    <div style={{ position: 'absolute', zIndex: 999, left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                        <h4 className="ritual-mes__header-titulo">{ritual.nome}</h4>
                        <p className="ritual-mes__header-subtitulo">{ritual.descricao.toUpperCase()}</p>
                    </div>
                </section>
                <section className="ritual-mes__funcao">
                    <h6 className="ritual-mes__funcao-titulo">Qual é a função desse ritual?</h6>
                    <ReactMarkdown className="ritual-mes__funcao-text">{ritual.funcao}</ReactMarkdown>
                </section>
                <section className="ritual-mes__itens">
                    <p className="ritual-mes__itens-titulo">Itens para fazer o ritual em seu lar</p>
                    <ReactMarkdown className="ritual-mes__itens-text">{ritual.itens}</ReactMarkdown>
                </section>
                <section className="ritual-mes__como-fazer">
                    <ReactMarkdown className="ritual-mes__como-fazer-text">{ritual.como_fazer}</ReactMarkdown>
                    <p className="ritual-mes__como-fazer-text"
                        style={{ color: '#5F5F5F', fontSize: '1.5rem', marginTop: '2.5rem' }}><strong>Obs.:</strong> Sempre que fizer um ritual, faça em seu altar ou em um lugar seguro. Coloque as velas em protetores, longe de tecidos e materiais inflamáveis. Limpe sempre a casa material e espiritualmente com incensos, sinergias e bons pensamentos.</p>
                    <div className="ritual-mes__como-fazer-titulo">
                        Como fazer<br />seu ritual?
                    </div>
                </section>
                <section className="ritual-mes__footer">
                    <span className="ritual-mes__footer-titulo">Onde adquirir</span>
                    <p className="ritual-mes__footer-text">Acesse nosso site: <a href={ritual.link_temsonline} target="_blank" rel="noreferrer">Link do ritual</a> ou entre em contato com a nossa <a href="https://api.whatsapp.com/send?phone=5511998117698" target="_blank" rel="noreferrer">Central de Atendimentos +55 (11) 9 9811-7698</a></p>
                </section>
            </main>
        );
    }

    return (
        content
    );
}
