import { useSwiper } from "swiper/react";
import rightArrow from "assets/right.png";

function SwiperButtonNext() {
  const swiper = useSwiper();
  return (
    <div className="main-slider__arrow right" onClick={() => swiper.slideNext()}>
      <img src={rightArrow} alt="" className="main-slider__arrow-img" />
    </div>
  );
}

export default SwiperButtonNext;
