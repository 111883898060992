import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const BASE_URL = process.env.REACT_APP_STRAPI_URL + '/api';

const atendimentosApi = createApi({
    reducerPath: 'atendimentos',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}`,
        prepareHeaders: headers => {
            headers.set("Authorization", process.env.REACT_APP_STRAPI_TOKEN_API_KEY);
            return headers;
        }
    }),
    endpoints(builder) {
        return {
            fetchAtendimentos: builder.query({
                query: () => {
                    return {
                        url: "atendimentos?populate=*",
                        method: "GET"
                    };
                }
            }),
            fetchAtendimentosByCityId: builder.query({
                query: id => {
                    return {
                        url: `sedes/${id}?populate=*`,
                        method: "GET"
                    }
                }
            }) 
        }
    }
});


export const {
    useFetchAtendimentosQuery,
    useFetchAtendimentosByCityIdQuery
} = atendimentosApi;
export { atendimentosApi };