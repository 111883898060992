import React, { useEffect } from 'react'
import CelularQRCodeAjude from 'assets/celular_qrcode.png'
import CelularPixDefault from 'assets/celular_pix_1.png'
import CelularPixAjudeDesktop from 'assets/celular_pix_2.png'
import CelularPixMobile from 'assets/celular_qrcode_mobile.png'
import CelularPixCaravanas from 'assets/celular_pix_caravanas.png'
import GilbertoCaravanas from 'assets/icone_gilberto_caravanas.png'
import GilbertoAjudeTems from 'assets/icone_gilberto_ajude_tems.png'
import WpIcon from 'assets/wp-icon.png'
import { useParams } from 'react-router-dom'
import { motion } from "framer-motion";
import { isMobile } from 'react-device-detect'

export default function Pix() {

    let { tipo } = useParams();

    const ehCaravana = tipo === 'caravanas';
    const pix = ehCaravana ? 'caravanasdeluz@tems.com.br' : '33.457.404/0001-68';
    const iconeGilberto = ehCaravana ? GilbertoCaravanas : GilbertoAjudeTems;
    const imgCelularPix = ehCaravana ? CelularPixCaravanas : CelularPixAjudeDesktop;

    useEffect(() => {
        document.title = 'Pix';
        document.documentElement.scrollTop = 0;
    }, []);

    const imgAttributes = !isMobile && {
        initial: { opacity: 0, y: '80px' },
        whileInView: { opacity: 1, y: 0 },
        transition: { duration: .8 }
    };

    const textAttributes = !isMobile && {
        initial: { opacity: 0, x: '80px' },
        transition: { duration: 1 },
        whileInView: { opacity: 1, x: 0 }
    };

    const copy = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            alert('Código copiado com sucesso!');
        } catch (err) {
            alert('Não foi possível copiar o código. Por favor, tente novamente.');
        }
    }   

    return (
        <div className='pix'>
            <div className='pix__doe'>
                <img className='pix__icone-gilberto' src={iconeGilberto} />
                <div className='pix__text-img-box'>
                    <div className='pix__text-box'>
                        <div className='pix__doe-pix-container'>
                            <p className='pix__doe-text'>Doe&nbsp;</p>
                            <p className='pix__pix-text'>com <span>PIX</span></p>
                        </div>

                        <div className='pix__chave' >
                            Chave PIX<br />
                            <span><strong>{pix}</strong></span>
                        </div>
                        <p>Abra o aplicativo do seu banco e {isMobile ? 'cole o código do qr code' : 'escaneie o qr code'}</p>
                        {isMobile && <button className='pix__botao-copiar' onClick={() => copy(pix)}>Copiar código</button>}
                    </div>

                    <motion.img
                        {...imgAttributes}
                        className='pix__celular-img'
                        src={isMobile ? CelularPixMobile : CelularQRCodeAjude}
                    />
                </div>
            </div>
            <div className='pix__como-acessar'>
                <motion.img {...imgAttributes} className='pix__celular-img' src={CelularPixDefault} />
                <motion.div
                    {...textAttributes}
                    className='pix__text-box'>
                    <h3>Como<br />Acessar?</h3>
                    <p>No aplicativo ou site do seu banco, procure pela opção PIX.</p>
                    <br />
                    <p>Essa nova modalidade está disponível 24h por dia e a transação é feita em menos de 10 segundos!</p>
                </motion.div>
            </div>
            <div className='pix__como-fazer'>
                <motion.div
                    {...textAttributes}
                    className='pix__text-box'>
                    <h3>Como<br />Fazer?</h3>
                    <p>Você não precisa mais digitar o número da agência e conta.<br />
                        <br /><strong>1-</strong> Ao abrir a tela do PIX, escolha a opção {ehCaravana ? 'e-mail' : 'CNPJ'} <br />
                        <strong>2-</strong> Em seguida, digite <strong>{ehCaravana ? 'caravanasdeluz@tems.com.br' : '33.457.404/0001-68'} <br />3-</strong> Escolha o valor que deseja doar</p>
                    <br />
                </motion.div>
                <motion.img {...imgAttributes} className='pix__celular-img' src={imgCelularPix} />
            </div>
            <div className='pix__comprovante'>
                <div className='pix__text-box'>
                    <h3>Confirmação e comprovante</h3>
                    <p>Confira se aparece o nome como Centro Espiritual Maria Santíssima na tela de confirmação e pronto!<br /><br />Você pode enviar seu comprovante através do Whatsapp</p>
                </div>

                <a className='pix__button' href='https://api.whatsapp.com/send?phone=5511998117698' target='_blank'>
                    <img src={WpIcon} />
                    (11) 99811-7698
                </a>
            </div>
        </div>
    )
}
