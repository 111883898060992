import { useSwiper } from "swiper/react";
import leftArrow from "assets/left.png";

function SwiperButtonPrev() {
  const swiper = useSwiper();
  return (
    <div className="main-slider__arrow left" onClick={() => swiper.slidePrev()}>
      <img src={leftArrow} alt="" className="main-slider__arrow-img" />
    </div>
  );
}

export default SwiperButtonPrev;
