import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import CarouselItem from "./CarouselItem";
import SwiperSliderButtonPrev from "../Swiper/SwiperSliderButtonPrev";
import SwiperSliderButtonNext from "../Swiper/SwiperSliderButtonNext";

function CarouselList({ data }) {

  const caravanasPosts = data.attributes.caravana_de_luzs.data.map(item => ({ ...item, tipo: 'caravanas' }));
  const rituaisPosts = data.attributes.ritual_do_mes.data.map(item => ({ ...item, tipo: 'rituais' }));
  const noticias = data.attributes.noticias.data.map(item => ({ ...item, tipo: 'noticias' }));

  const postsArray = [...noticias, ...caravanasPosts, ...rituaisPosts].sort((a, b) => 0.5 - Math.random());

  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Autoplay, A11y]}
        spaceBetween={0}
        slidesPerView={1}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        navigation
        loop={true}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
      >
        <SwiperSliderButtonPrev />
        <SwiperSliderButtonNext />

        {postsArray.map(post => {
          return (
            <SwiperSlide key={post.id}>
              <CarouselItem id={post.id} post={post.attributes} tipo={post.tipo} />
            </SwiperSlide>
          );
        })}


      </Swiper>
    </>
  );
}

export default CarouselList;
