import { motion } from "framer-motion";
import { Link } from "react-router-dom";

function ServiceItem({ name, img, path }) {

    const isMobile = window.innerWidth < 768;

    const attributes = !isMobile ? {
        initial: { opacity: 0, y: 50 },
        transition: { duration: 1 },
        whileInView: { opacity: 1, y: 0 }
    } : '';

    const content = (name === 'Ajude o TEMS' || name === 'Nomes para Cerimônia do Algodao') ?
        <a target="_blank" className="outros-servicos__box" href={path}>
            <img src={img} alt={name} className="outros-servicos__img" />
            <motion.p
                className="outros-servicos__title"
                {...attributes}>{name}</motion.p>
        </a> : <Link to={path}>
            <div className="outros-servicos__box">
                <img src={img} alt={name} className="outros-servicos__img" />
                <motion.p
                    className="outros-servicos__title"
                    {...attributes}>{name}</motion.p>
            </div>
        </Link>

    return (
        content
    );
}

export default ServiceItem;