import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function NotFound() {
  useEffect(() => {
    document.title = 'Página não encontrada';
  }, []);

  return (
    <div className='notfound'>
        <h1 className='notfound__404'>404</h1>
        <h4 className='notfound__title'>Desculpe, página não encontrada</h4>

        <Link to="/" className='notfound__button'>Voltar a página inicial</Link>
    </div>
  )
}
