import { Swiper, SwiperSlide } from "swiper/react";
import { A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import PixImg from 'assets/pix.png'
import CaixaEconomicaImg from 'assets/caixa.png'
import ItauImg from 'assets/itau.png'
import BradescoImg from 'assets/bradesco.png'
import BanrisulImg from 'assets/banrisul.png'
import BancoBrasilImg from 'assets/banco_do_brasil.png'

import ComoAjudarData from './como-ajudar.json'
import { useEffect, useRef, useState } from 'react'

import CestasBasicasImg from 'assets/comoajudar-cestas-basicas.png'
import AjudeTemsImg from 'assets/comoajudar-ajude-tems.png'
import CaravanasImg from 'assets/comoajudar-caravanas.png'
import Background from 'assets/comoajudar-background.png'
import MobileBackground from 'assets/comoajudar_mobile_background.png'

import IconeGilbertoCestas from 'assets/icone_gilberto_cestas.png'
import IconeGilbertoCaravanas from 'assets/icone_gilberto_caravanas.png'
import IconeGilbertoAjude from 'assets/icone_gilberto_ajude_tems.png'
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

function ComoAjudarPage() {

    const [data, setData] = useState(ComoAjudarData[0])
    const [swiper, setSwiper] = useState();
    const resultRef = useRef(null);

    const icons = [IconeGilbertoCestas, IconeGilbertoCaravanas, IconeGilbertoAjude];

    useEffect(() => {
        document.title = 'Como Ajudar';
        document.getElementById('cestas-basicas').click()
    }, []);

    const scrollToMain = () => {
        resultRef.current.scrollIntoView({ block: 'center', behavior: "smooth" });
    }

    const handleDataChange = (label) => {
        clearButtonsContent()
        document.getElementById(label).classList.add('como-ajudar__campanha-option-selected')

        const filteredData = ComoAjudarData.filter(comoAjudarOption => {
            return comoAjudarOption.name === label
        })

        setData(filteredData[0])
    }

    const clearButtonsContent = () => {
        document.getElementById('list').childNodes.forEach(element => {
            element.classList.remove('como-ajudar__campanha-option-selected')
        })
    }

    let content = <div className="como-ajudar__slider">
        <Swiper
            modules={[A11y]}
            slidesPerView={1}
            className="swiper-no-swiping"
            loop={true}
            onSwiper={(swiper) => setSwiper(swiper)}
        >
            <SwiperSlide>
                <img className="como-ajudar__slider-img" src={CestasBasicasImg} alt='' />
            </SwiperSlide>

            <SwiperSlide>
                <img className="como-ajudar__slider-img" src={AjudeTemsImg} alt='' />
            </SwiperSlide>

            <SwiperSlide>
                <img className="como-ajudar__slider-img" src={CaravanasImg} alt='' />
            </SwiperSlide>
        </Swiper>
    </div>

    return (
        <div className="como-ajudar">
            <div className="como-ajudar__header">
                <picture>
                    <source media="(max-width: 799px)" srcSet={MobileBackground}/>
                    <source  media="(min-width: 800px)" srcSet={Background}/>
                    <img src={Background} alt="" className="como-ajudar__header-img" />
                </picture>

                <div className="como-ajudar__header-pix-box">
                    <div className="como-ajudar__header-text-box">
                        <h5>Chave Pix <span>{(data.name === 'ajude-tems' || data.name === 'cestas-basicas') ? '33.457.404/0001-68' : 'caravanasdeluz@tems.com.br'}</span></h5>
                        <p>Centro Espiritualista Maria Santíssima</p>
                    </div>

                    <div className="como-ajudar__header-icone-box">
                        <div>
                            <p>Não sabe usar o pix?</p>
                            <Link to={`/pix/${data.name}`}><span>Clique aqui</span></Link>
                        </div>
                        <img src={icons[data.id]} alt="" />
                    </div>
                </div>
            </div>
            <main className="como-ajudar__campanha">

                <div className="como-ajudar__campanha-box">
                    <div style={{ position: 'relative' }} className="como-ajudar__campanha-box--container">

                        <h6 className="como-ajudar__campanha-title">Escolha a campanha que voce deseja doar</h6>
                        <ul id="list" className="como-ajudar__campanha-options"    ref={resultRef}>
                            <li id="ajude-tems" className="como-ajudar__campanha-option" onClick={() => {
                                scrollToMain()
                                swiper && swiper.slideTo(1)
                                return handleDataChange('ajude-tems')
                            }
                            }>Ajude o TEMS</li>
                            <li id="cestas-basicas" className="como-ajudar__campanha-option" onClick={() => {
                                swiper && swiper.slideTo(0)
                                return handleDataChange('cestas-basicas')
                            }
                            }>Cesta Básica</li>
                            <li id="caravanas" className="como-ajudar__campanha-option" onClick={() => {
                                scrollToMain()
                                swiper && swiper.slideTo(2)
                                return handleDataChange('caravanas')
                            }
                            }>Caravanas de Luz</li>
                        </ul>

                        {isMobile && content}

                        <div className="como-ajudar__button-box">
                            {data.options.map((botao, index) => {
                                return (
                                    <button
                                        key={Math.random()}
                                        onClick={() => { window.open(botao.link, '_blank') }}
                                        className="como-ajudar__donation-button">{botao.label}<br />
                                        { botao.total && <span className="como-ajudar__donation-button-total">{botao.total}</span>}
                                    </button>
                                );
                            })}
                        </div>
                    </div>

                    {!isMobile && content}
                </div>

                <div className="como-ajudar__contas">
                    <ul className="como-ajudar__list">
                        <li className="como-ajudar__item">
                            <img src={PixImg} alt="" />
                            <div className="como-ajudar__text-box">
                                <p><strong>Chave pix</strong> 33.457.404/0001-68</p>
                                <p><strong>Centro Espiritualista Maria Santíssima</strong></p>
                            </div>
                        </li>
                        <li className="como-ajudar__item">
                            <img src={CaixaEconomicaImg} alt="" />
                            <div className="como-ajudar__text-box">
                                <p><strong>Banco</strong> Caixa Econômica</p>
                                <p><strong>Centro Espiritualista Maria Santíssima</strong></p>
                                <p><strong>Agência</strong> 1231</p>
                                <p><strong>Código Operação</strong> 003</p>
                                <p><strong>Conta Corrente</strong> 2471-0</p>
                                <p><strong>CNPJ</strong> 33.457.404/0001-68</p>
                            </div>
                        </li>
                        <li className="como-ajudar__item">
                            <img src={ItauImg} alt="" />
                            <div className="como-ajudar__text-box">
                                <p><strong>Banco</strong> Itaú</p>
                                <p><strong>Centro Espiritualista Maria Santíssima</strong></p>
                                <p><strong>Agência</strong> 0585</p>
                                <p><strong>Conta corrente</strong> 24244-9</p>
                                <p><strong>CNPJ</strong> 33.457.404/0001-68</p>
                            </div>
                        </li>
                        <li className="como-ajudar__item">
                            <img src={BradescoImg} alt="" />
                            <div className="como-ajudar__text-box">
                                <p><strong>Banco</strong> Bradesco</p>
                                <p><strong>Centro Espiritualista Maria Santíssima</strong></p>
                                <p><strong>Agência</strong> 0114</p>
                                <p><strong>Conta corrente</strong> 0452413-6</p>
                                <p><strong>CNPJ</strong> 33.457.404/0001-68</p>
                            </div>
                        </li>
                        <li className="como-ajudar__item">
                            <img src={BancoBrasilImg} alt="" />
                            <div className="como-ajudar__text-box">
                                <p><strong>Banco</strong> Banco do Brasil</p>
                                <p><strong>Instituto Colônia Esperança</strong></p>
                                <p><strong>Agência</strong> 0368-9</p>
                                <p><strong>Conta corrente</strong> 77.332-8</p>
                                <p><strong>CNPJ</strong> 31.228.697/0001-86</p>
                            </div>
                        </li>
                        <li className="como-ajudar__item">
                            <img src={BanrisulImg} alt="" />
                            <div className="como-ajudar__text-box">
                                <p><strong>Banco</strong> Banrisul</p>
                                <p><strong>Centro Espiritualista Maria Santíssima</strong></p>
                                <p><strong>Agência</strong> 0413</p>
                                <p><strong>Conta corrente</strong> 061085390-4</p>
                                <p><strong>CNPJ</strong> 33.457.404/0001-68</p>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="como-ajudar__outside-donation">
                    <div className="como-ajudar__outside-donation-text-box">
                        <p className="como-ajudar__outside-donation-title">Donation Outside Brazil</p>
                        <p>Use PAYPAL to make your donation</p>
                        <ol className="como-ajudar__outside-donation-list">
                            <li className="como-ajudar__outside-donation-item">Click on the button</li>
                            <li className="como-ajudar__outside-donation-item">Choose the donation amount</li>
                            <li className="como-ajudar__outside-donation-item">Choose the reason for donations or do not fill</li>
                            <li className="como-ajudar__outside-donation-item">Donate</li>
                        </ol>
                    </div>
                    <div className="como-ajudar__outside-donation-button-box">
                        <button className="como-ajudar__outside-donation-button dollar" onClick={() => window.open('https://www.paypal.com/donate/?hosted_button_id=BXP2XLYTT2R2A', '_blank')}>In Dollar</button>
                        <button className="como-ajudar__outside-donation-button euro" onClick={() => window.open('https://www.paypal.com/donate/?hosted_button_id=NAGBPWUT2D8HG', '_blank')}>In Euro</button>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default ComoAjudarPage;