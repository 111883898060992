import "./sass/main.scss";
import Header from "./components/ui/Header/Header";
import Footer from "./components/ui/Footer/Footer";
import HomePage from "./pages/Home";
import AtendimentosPage from './pages/Atendimentos/AtendimentosPage';
import ComoAjudarPage from "./pages/Como Ajudar";
import PrecePage from "./pages/Preces";
import ContatoPage from "./pages/Contato";
import RitualDoMesPage from "./pages/Ritual Mes";
import { Routes, Route, Navigate, useLocation, NavLink } from "react-router-dom";
import CaravanasDeLuzPage from "pages/Caravanas de Luz";
import CaravanaEscolhida from "pages/Caravanas de Luz/CaravanaEscolhida";
import AtendimentoSaoPaulo from "pages/Atendimentos/AtendimentoSaoPaulo";
import { useEffect, useRef, useState } from "react";
import AtendimentoSede from "pages/Atendimentos/AtendimentoSede";
import ReactGA from 'react-ga';
import NotFound from "pages/NotFound";
import Pix from "pages/Como Ajudar/Pix";
import Ritual from "pages/Ritual Mes/Ritual";
import Prece from "pages/Preces/Prece";
import PoliticaPrivacidade from "pages/PoliticaPrivacidade";
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import CerimoniaAlgodaoPage from "pages/CerimoniaAlgodao";
import SacolinhasNatal from "pages/SacolinhasNatal";
import SacolinhasVideo from "assets/sacolinhas.mp4";

ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

function App() {
  let location = useLocation();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  const [isOpen, setIsOpen] = useState(true);

  const resultRef = useRef(null);
  return (
    <div className="container">
        <Header resultRef={resultRef} />
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/atendimentos" element={<AtendimentosPage />}>
            <Route path="" element={<Navigate to="sede/1" replace />} />
            <Route exact path="sao-paulo" element={<AtendimentoSaoPaulo />} />
            <Route exact path="sede/:id" element={<AtendimentoSede />} />
          </Route>
          <Route exact path="/caravanas" element={<CaravanasDeLuzPage />} />
          <Route exact path="/caravanas/:id" element={<CaravanaEscolhida />} />
          <Route exact path="/como-ajudar" element={<ComoAjudarPage />} />
          <Route exact path="/rituais" element={<RitualDoMesPage />} />
          <Route exact path="/rituais/:id" element={<Ritual />} />

          <Route exact path="/preces" element={<PrecePage />} />
          <Route exact path="/preces/:id" element={<Prece />} />
          <Route exact path="/contato" element={<ContatoPage />} />
          <Route exact path="/pix/:tipo" element={<Pix />} />

          <Route exact path="/politica-privacidade" element={<PoliticaPrivacidade />} />
          <Route exact path="/cerimonia-algodao" element={<CerimoniaAlgodaoPage />} />
          <Route exact path="/sacolinhas" element={<SacolinhasNatal />} />

        </Routes>

        <Footer ref={resultRef} />
      </div>
  );
}

export default App;
