import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useFetchAtendimentosByCityIdQuery } from 'store'
import Popup from 'components/ui/Popup/Popup'

export default function AtendimentoSede() {

  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [popupTitle, setPopupTitle] = useState('');

  let { id } = useParams()


  const currentDate = new Date()

  const { data, error, isFetching } = useFetchAtendimentosByCityIdQuery(id)

  useEffect(() => {
    if (data) document.title = `Atendimento ${data.data.attributes.nome}`;
  }, [data]);

  let content = ''

  const getMonthFormatted = (month) => {
    switch (month.toString()) {
      case '0':
        return 'Janeiro'
      case '1':
        return 'Fevereiro'
      case '2':
        return 'Março'
      case '3':
        return 'Abril'
      case '4':
        return 'Maio'
      case '5':
        return 'Junho'
      case '6':
        return 'Julho'
      case '7':
        return 'Agosto'
      case '8':
        return 'Setembro'
      case '9':
        return 'Outubro'
      case '10':
        return 'Novembro'
      case '11':
        return 'Dezembro'

      default:
        break;
    }
  }

  const handleDayClick = (atendimento, titulo) => {
    const content = (
      <>
        <p><span>{atendimento.titulo}</span></p>
        <p><span>Horário</span> A partir das {atendimento.horario}</p>
        {atendimento.senha && <p><span>Senha</span> {atendimento.senha}</p>}
        <p><span>Endereço</span> {atendimento.endereco}</p>
        <p><span>Contato</span> {atendimento.Contato}</p>
      </>
    );
    setShowPopup(true);
    setPopupContent(content);
    setPopupTitle(titulo)
  };


  if (data) {

    let sede = data.data.attributes

content = (
  <>
    <div className='atendimentos-sede'>
      <h4 className='atendimentos-sede__titulo'>Atendimento em {sede.nome}</h4>

      <ul className='atendimentos-sede__list'>
        {
          [0, 1, 2].map(item => {
            const ano = currentDate.getFullYear() + item;

            return (
              <li key={item} className='atendimentos-sede__item'>
                <p className='atendimentos-sede__item-titulo'>{getMonthFormatted(currentDate.getMonth() + item)}, {ano - item}</p>

                <ul className='atendimentos-sede__days-list'>
                  {
                    sede.atendimentos.data.filter(response => {
                      return new Date(response.attributes.data + 'T00:00:00').getMonth() === currentDate.getMonth() + item
                    })
                    .sort((a, b) => new Date(a.attributes.data + 'T00:00:00') - new Date(b.attributes.data + 'T00:00:00'))
                    .map(response => {
                      let atendimento = response.attributes
                      let dataAtendimento = new Date(atendimento.data + 'T00:00:00')

                      return (
                        <li key={response.id} className='atendimentos-sede__day'>
                          <div className='atendimentos-sede__day-text-box'>
                            <span className='atendimentos-sede__day-text-date'>Dia {dataAtendimento.getDate()}</span>
                            <span className='atendimentos-sede__day-text-hour'>{atendimento.horario}</span>
                          </div>
                          <div onClick={() => handleDayClick(atendimento, `Dia ${dataAtendimento.getDate()} às ${atendimento.horario}`)} className='botao'>saiba mais</div>
                        </li>
                      )
                    })
                  }
                </ul>
              </li>
            )
          })
        }
      </ul>
    </div>
    {showPopup && <Popup content={popupContent} title={popupTitle} onClose={() => setShowPopup(false)} />}
  </>
)
  }

  return content
}
