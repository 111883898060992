import Spotify from 'assets/spotify.png';
import Twitter from 'assets/twitter.png';
import X from 'assets/X.png';
import YoutubeFullfield from 'assets/youtube_fullfield.png';
import Youtube from 'assets/youtube.png';
import Facebook from 'assets/facebook.png';
import Instagram from 'assets/instagram.png';
import Phone from 'assets/phone.png';
import Location from 'assets/location.png';
import Mail from 'assets/mail.png';
import Logo from 'assets/logo_branco_horizontal.png';
import { forwardRef } from 'react';

const Footer = forwardRef((props, ref) => {

    const midias = [
        {
            img: Facebook,
            link: 'https://www.facebook.com/templomariasantissima/'
        },
        {
            img: Instagram,
            link: 'https://www.instagram.com/temsoficial/?hl=pt-br'
        },
        {
            img: X,
            link: 'https://X.com/temsoficial'
        },
        {
            img: YoutubeFullfield,
            link: 'https://www.youtube.com/@TEMSAOVIVO/videos'
        },
        {
            img: Spotify,
            link: 'https://open.spotify.com/user/629bdz4ue49221e9n84kc0w2e'
        },
        {
            img: Youtube,
            link: 'https://www.youtube.com/@TEMSAOVIVO/videos'
        }
    ]
    return (
        <footer ref={ref} className="footer">
            <section className="footer__quem-somos">
                <img className="footer__logo" src={Logo} />
                <p className="footer__text">
                    O Templo Espiritual Maria Santíssima é uma casa
                    ecumênica, sem fins lucrativos e que tem como principal
                    meta propagar os Ensinamentos de Jesus e o Auxílio ao
                    Próximo para difundir o amor e a caridade exemplificados pelo Cristo.
                </p>
                <span className='footer__copy'>Copyright &copy; 2024 Templo Maria Santíssima</span>
            </section>
            <section className="footer__midias-sociais">
                <div className="footer__midias-sociais--box">
                    <ul className="footer__midias-sociais--list">
                        {
                            midias.map((midia, index) => {
                                return (
                                    <li key={index}>
                                        <a href={midia.link} target='__blank'>
                                            <img src={midia.img} alt="" className='footer__midias-sociais--img' />
                                        </a>
                                    </li>
                                );
                            })
                        }
                    </ul>
                    <div className='footer__midias-sociais--text'>
                        Siga o TEMS
                        <br />nas redes sociais
                    </div>
                </div>

            </section>
            <section className="footer__contato">
                <div className="footer__contato-item">
                    <img src={Phone} alt="" className="footer__contato-icon" />
                    <p>+55 (11) 3354-1500 (PABX)</p>
                </div>
                <div className="footer__contato-item">
                    <img src={Mail} alt="" className="footer__contato-icon" />
                    <p>faleconosco@tems.com.br</p>
                </div>
                <div className="footer__contato-item">
                    <img src={Location} alt="" className="footer__contato-icon" />
                    <p>Rua Jaraguá, 371 - Bom Retiro, São Paulo/SP
                        <br />CEP: 01129-000</p>
                </div>
            </section>
        </footer>
    );
})

export default Footer;