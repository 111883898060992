import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const BASE_URL = 'https://ajude.tems.com.br/give-api/forms';

const ajudeTemsApi = createApi({
    reducerPath: 'ajudeTems',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}`
    }),
    endpoints(builder){
        return{
            fetchDonationPercentage: builder.query({
                query: () => {
                    return {
                        url: '',
                        params: {
                            form: '9461'
                        },
                        method: 'GET'
                    };
                }
            })
        };
    }
});

export const {useFetchDonationPercentageQuery}  = ajudeTemsApi;
export {ajudeTemsApi};