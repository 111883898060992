import { useFetchProductsQuery } from "store/apis/produtosApi";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y, Autoplay } from "swiper";
import { Oval } from "react-loader-spinner";
import ProductItem from "./ProductItem";
import SwiperStoreButtonPrev from "../Swiper/SwiperStoreButtonPrev";
import SwiperStoreButtonNext from "../Swiper/SwiperStoreButtonNext";

function ProductsList() {
  const { data, error, isFetching } = useFetchProductsQuery();

  let content;

  if (isFetching || error) {
    content = <div style={{ width: '86vw', height: '25vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Oval
        height={80}
        width={80}
        color="#002a89"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="lightblue"
        strokeWidth={2}
        strokeWidthSecondary={2}

      />
    </div>;
  } else {
    content = (
      <>

        <Swiper
          loop
          modules={[Navigation, A11y]}
          spaceBetween={0}
          slidesPerView={4}
          breakpoints={{
            300: {
              width: 300,
              slidesPerView: 1,
            },
            768: {
              width: 768,
              slidesPerView: 2
            },
            1200: {
              width: 1200,
              slidesPerView: 3
            },
          }}
          navigation={{
            nextEl: '.loja-tems__arrow-right',
            prevEl: '.loja-tems__arrow-left',
          }}
        >

          {content}
          {data.map(product => (
            <SwiperSlide key={product.id}>
              <ProductItem
                name={product.name}
                permalink={product.permalink}
                images={product.images}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <SwiperStoreButtonPrev />
        <SwiperStoreButtonNext />
      </>
    );
  }

  return (
    <div className="loja-tems">
      <div className="loja-tems__mark">LOJA TEMS</div>
      <div className="loja-tems__list-box">{content}</div>
    </div>
  );
}

export default ProductsList;
