import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const BASE_URL = process.env.REACT_APP_STRAPI_URL + '/api';

const precesApi = createApi({
    reducerPath: 'preces-e-oracoes',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}`,
        prepareHeaders: headers => {
            headers.set("Authorization", process.env.REACT_APP_STRAPI_TOKEN_API_KEY);
            return headers;
        }
    }),
    endpoints(builder) {
        return {
            fetchPreces: builder.query({
                query: () => {
                    return {
                        url: "preces-e-oracoes?populate=*",
                        method: "GET"
                    };
                }
            }),
            fetchPrecesById: builder.query({
                query: id => {
                    return {
                        url: `preces-e-oracoes/${id}?populate=*`,
                        method: "GET"
                    }
                }
            })
        }
    }
});


export const {
    useFetchPrecesQuery,
    useFetchPrecesByIdQuery
} = precesApi;
export { precesApi };