import { useFetchVelasQuery } from 'store';
import VelasDados from '../rituais.json'
import VelaBotaoImg from 'assets/vela-botao.png';
import { isMobile } from 'react-device-detect';
import { A11y, Autoplay, Navigation } from "swiper";
import { SwiperSlide, Swiper } from "swiper/react";
import SwiperVelasButtonNext from 'components/ui/Swiper/SwiperVelasButtonNext';
import SwiperVelasButtonPrev from 'components/ui/Swiper/SwiperVelasButtonPrev';


export default function Velas() {
    const local = VelasDados;
    const { data } = useFetchVelasQuery();

    let content;

    const getColorDetails = (color) => {
        return local.find(vela => vela.nome === color)
    }

    if (data) {
        let velas = data.data;

        content = isMobile ? (
            <>
                <Swiper
                    modules={[Navigation, Autoplay, A11y]}
                    centeredSlides={true}
                    centeredSlidesBounds={true}
                    spaceBetween={40}
                    slidesPerView={1}
                    loop={true}
                    scrollbar={{ draggable: true }}
                    navigation={{
                        nextEl: '.rituais__velas-arrow-right',
                        prevEl: '.rituais__velas-arrow-left',
                    }}
                >
                    {velas.map(vela => {
                        const { img, background } = getColorDetails(vela.attributes.cor)

                        return (
                            <SwiperSlide key={vela.id}>
                                <li
                                    className="rituais__vela"
                                >
                                    <div className="rituais__vela-side rituais__vela-side--front" style={{ background }}>
                                        <figure className="rituais__vela-figure">
                                            <img className="rituais__vela-img" src={img} />
                                            <figcaption className="rituais__vela-text-box">
                                                <p className="rituais__vela-mes">{vela.attributes.mes}</p>
                                                <p className="rituais__vela-cor">{vela.attributes.cor}</p>
                                            </figcaption>
                                        </figure>

                                        <div className="rituais__vela-botao">
                                            <img src={VelaBotaoImg} />
                                        </div>
                                    </div>


                                    <div className="rituais__vela-side rituais__vela-side--back">
                                        <p className='rituais__vela-descricao'>
                                            {vela.attributes.descricao}
                                        </p>
                                    </div>
                                </li>
                            </SwiperSlide>
                        )
                    })}
                </Swiper >
                <SwiperVelasButtonNext/>
                <SwiperVelasButtonPrev/>    
            </>
        ) : (
            <ul className="rituais__velas-list">
                {velas.map(vela => {

                    const { img, background } = getColorDetails(vela.attributes.cor)
                    return (
                        <li
                            className="rituais__vela"
                            key={vela.id}>

                            <div className="rituais__vela-side rituais__vela-side--front" style={{ background }}>
                                <figure className="rituais__vela-figure">
                                    <img className="rituais__vela-img" src={img} />
                                    <figcaption className="rituais__vela-text-box">
                                        <p className="rituais__vela-mes">{vela.attributes.mes}</p>
                                        <p className="rituais__vela-cor">{vela.attributes.cor}</p>
                                    </figcaption>
                                </figure>

                                <div className="rituais__vela-botao">
                                    <img src={VelaBotaoImg} />
                                </div>
                            </div>


                            <div className="rituais__vela-side rituais__vela-side--back">
                                <p className='rituais__vela-descricao'>
                                    {vela.attributes.descricao}
                                </p>
                            </div>
                        </li>
                    )
                })}

            </ul>
        )
    }
    return (
        content
    )
}
