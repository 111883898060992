import { Link } from 'react-router-dom';

function CarouselItem({ id, post, tipo }) {

  const img = post.capa_destaque.data.attributes.url;
  const title = post.titulo_destaque;

  let destaque;

  switch (tipo) {
    case 'caravanas':
      destaque = {
        link: `caravanas/${id}`,
        sub: 'CARAVANAS DE LUZ'
      }
      break;
    case 'rituais':
      destaque = {
        link: `rituais/${id}`,
        sub: 'RITUAIS E CERIMÔNIAS'
      }
      break;
    case 'noticias':
      destaque = {
        link: post.link,
        sub: 'NOTÍCIAS'
      }
      break;
  }


  return (
    <Link className='main-slider__box' to={destaque.link} target='_blank'>
      <img
        src={img}
        alt="Imagem Principal"
        className="main-slider__img"
      />

      <div className="main-slider__text-box">
        <div className="main-slider__subtitle-box">{destaque.sub}</div>
        <h3 className="main-slider__title">{title}</h3>
      </div>
    </Link>
  );
}

export default CarouselItem;