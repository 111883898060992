import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { caravanasApi } from "./apis/caravanasApi";
import { produtosApi } from "./apis/produtosApi";
import { ajudeTemsApi } from "./apis/ajudeTemsApi";
import { ultimasNoticiasApi } from "./apis/ultimasNoticiasApi";
import { atendimentosApi } from "./apis/atendimentosApi";
import { rituaisTemsApi } from "./apis/rituaisTemsApi";
import { precesApi } from "./apis/precesApi";

export const store = configureStore({
  reducer: {
    [ajudeTemsApi.reducerPath]: ajudeTemsApi.reducer,
    [caravanasApi.reducerPath]: caravanasApi.reducer,
    [produtosApi.reducerPath]: produtosApi.reducer,
    [precesApi.reducerPath]: precesApi.reducer,
    [rituaisTemsApi.reducerPath]: rituaisTemsApi.reducer,
    [ultimasNoticiasApi.reducerPath]: ultimasNoticiasApi.reducer,
    [atendimentosApi.reducerPath]: atendimentosApi.reducer,
  },

  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(ajudeTemsApi.middleware)
      .concat(caravanasApi.middleware)
      .concat(produtosApi.middleware)
      .concat(precesApi.middleware)
      .concat(rituaisTemsApi.middleware)
      .concat(atendimentosApi.middleware)
      .concat(ultimasNoticiasApi.middleware);
  },
});
setupListeners(store.dispatch);

export {
  useFetchCaravanasQuery,
  useFetchCaravanasByIdQuery
} from "./apis/caravanasApi";
export {
  useFetchPrecesQuery,
  useFetchPrecesByIdQuery
} from "./apis/precesApi";
export { useFetchProductsQuery } from "./apis/produtosApi";
export { useFetchRituaisQuery, useFetchRituaisByIdQuery, useFetchVelasQuery } from "./apis/rituaisTemsApi";
export { useFetchDonationPercentageQuery } from "./apis/ajudeTemsApi";
export { useFetchNewsQuery, useFetchRitualsNewsQuery } from "./apis/ultimasNoticiasApi";
export {
  useFetchAtendimentosQuery,
  useFetchAtendimentosByCityIdQuery
} from "./apis/atendimentosApi";
