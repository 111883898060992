import { useEffect } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useParams } from "react-router-dom"
import { useFetchPrecesByIdQuery } from "store";

export default function Prece() {

    const { id } = useParams();

    const { data } = useFetchPrecesByIdQuery(id);

    useEffect(() => {
        if (data) document.title = `${data.data.attributes.tipo.charAt(0).toUpperCase() + data.data.attributes.tipo.slice(1)} ${data.data.attributes.titulo}`;
    }, [data]);

    let content;

    if (data) {
        content = (
            <div className="prece">
                <section className="prece__header" style={{
                    backgroundImage: `url(${data.data.attributes.img.data.attributes.url})`
                }}></section>
                <ReactMarkdown>{data.data.attributes.texto}</ReactMarkdown>
            </div>
        );
    }


    return content;
}
