import React from 'react'

export default function PoliticaPrivacidade() {
    return (
        <div className='politica'>
            <h2>TERMO DE CONSENTIMENTO DE ACORDO COM A LEI GERAL DE PROTEÇÃO DE DADOS - LGPD</h2>
            <p>
                Estamos empenhados em salvaguardar a sua privacidade ao
                estabelecer esta relação conosco. Este termo tem a finalidade
                de deixar o mais claro possível a nossa política de coleta
                e compartilhamento de dados, informando sobre os dados coletados
                e como os utilizamos.
            </p>

            <p>
                Ao utilizar os nossos serviços, você declara o seu
                EXPRESSO CONSENTIMENTO para coletarmos, tratarmos e
                armazenarmos dados sobre você quando julgarmos necessários
                à prestação de nossos serviços, tais como:
            </p>

            <ul>
                <li>
                    Informações que você oferece. Coletamos os dados fornecidos por
                    você no cadastro, tais como nome e sobrenome, endereço para correspondência,
                    endereço de e-mail, informações de pagamento, CPF,
                    bem como outras informações de contato on-line ou número de telefone,
                    foto e demais informações requeridas no cadastro.
                </li>
                <li>
                    Comunicação. Podemos registrar e gravar todos os
                    dados fornecidos em toda comunicação realizada com nossa equipe,
                    seja por correio eletrônico, mensagens, telefone ou qualquer outro meio.
                </li>
                <li>
                    Informações sobre pagamentos. Ao submeter seus
                    dados para pagamento, podemos coletar informações
                    sobre a compra ou transação. Isso abrange suas
                    informações de pagamento, como os dados do seu cartão de
                    crédito ou débito, informações de conta e autenticação,
                    além dos dados de faturamento, tais como endereço completo, CPF e CNPJ.
                </li>
            </ul>

            <p>
                FINALIDADES: UTILIZAÇÃO DESTAS INFORMAÇÕES
                Ao utilizar a plataforma você AUTORIZA EXPRESSAMENTE o uso
                destas informações para as seguintes FINALIDADES:
            </p>

            <ul>
                <li>
                    Fornecer, melhorar e desenvolver os Serviços
                    disponíveis. Usamos os seus dados para melhorar a
                    qualidade dos nossos serviços, através da
                    personalização do serviço prestado.
                </li>
                <li>
                    Obrigações legais. Compartilhamos seus dados com
                    terceiros para cumprir exigências legais,
                    regulatórias ou fiscais, envolvendo a divulgação
                    de seus dados pessoais a terceiros, a um tribunal,
                    reguladores ou agências governamentais.
                </li>
                <li>
                    Informações que você oferece. Coletamos os dados
                    fornecidos por você no cadastro, tais como nome e
                    sobrenome, endereço para correspondência, endereço de e-mail,
                    informações de pagamento, endereço IP, CPF,
                    bem como outras informações de contato on-line ou
                    número de telefone, foto e demais informações
                    requeridas no cadastro ou vinculados às mídias utilizados no login.
                </li>
                <li>
                    Comunicação. Podemos registrar e gravar todos os
                    dados fornecidos em toda comunicação realizada
                    com nossa equipe, seja por correio eletrônico,
                    mensagens, telefone ou qualquer outro meio.
                </li>
                <li>
                    Informações sobre pagamentos. Ao submeter seus
                    dados para pagamento, podemos coletar informações
                    sobre a compra ou transação. Isso abrange suas
                    informações de pagamento, como os dados do seu
                    cartão de crédito ou débito, informações de conta
                    e autenticação, além dos dados de faturamento,
                    tais como endereço completo, CPF e CNPJ.
                </li>
                <li>
                    Endereço eletrônico (e-mail). Ao fazer login na plataforma,
                    coletaremos o seu e-mail para fins cadastrais,
                    pelo qual ocorrerão as comunicações de atualizações
                    da plataforma, promoções e gerenciamento de sua conta.
                </li>
                <li>
                    Registramos dados de sua visita à plataforma
                    através de cookies e outras tecnologias de rastreamento,
                    incluindo seu endereço IP e nome de domínio, a versão do
                    seu navegador e do seu sistema operacional, dados de
                    tráfego online, dados de localização, logs da web e
                    outros dados de navegação.
                </li>
            </ul>

            <p>
                FINALIDADES: UTILIZAÇÃO DESTAS INFORMAÇÕES
                Ao utilizar a plataforma você AUTORIZA EXPRESSAMENTE o
                uso destas informações para as seguintes FINALIDADES:
            </p>

            <ul>
                <li>
                    Fornecer, melhorar e desenvolver os Serviços disponíveis.
                    Usamos cookies e tecnologias semelhantes para fornecer e
                    apoiar nossos Serviços, através da personalização do conteúdo
                    compartilhado, para garantir que o conteúdo da plataforma seja relevante.
                </li>
                <li>
                    Construção de conteúdo. Todo conteúdo compartilhado, ou
                    divulgado, seja através de comentários, dicas ou sugestões
                    de melhorias compõem o banco de dados da plataforma
                    viabilizando a melhoria e conhecimento do produto pelos demais usuários.
                </li>
                <li>
                    Para reorganizar ou fazer mudanças operacionais e gerenciais da
                    plataforma. Os dados coletados serão utilizados na avaliação
                    de eventuais negociações para a venda da plataforma a terceiros;
                    para uma eventual reorganização interna, com a a transferência de
                    dados a terceiros (ou seus assessores) como parte de um processo
                    de due diligence com a finalidade de analisar uma proposta de venda ou reorganização.
                </li>
                <li>
                    Monitoramento de uso. Utilizamos seus dados para monitorar
                    atividades e transações com a finalidade de garantir a
                    qualidade do serviço, o atendimento à leis aplicáveis,
                    o cumprimento de procedimentos e para combater a fraude.
                </li>
                <li>
                    Obrigações legais. Compartilhamos seus dados com terceiros para
                    cumprir exigências legais, regulatórias ou fiscais,
                    envolvendo a divulgação de seus dados pessoais a terceiros,
                    a um tribunal, reguladores ou agências governamentais.
                </li>
            </ul>

            <p>
                COMPARTILHAMENTO DE DADOS
                Compartilhamos os seus dados com (indicar empresas ou
                ramo de atividade – ex. correios, Mandaê, Fedex,
                Bancos, Operadoras de cartões de crédito, etc) ,
                para fins de viabilizar pagamentos e o
                processamento de dados, bem como para tornar o
                produto mais relevante e eficiente à sua finalidade.
                Compartilhamos seus dados com terceiros para cumprir
                exigências legais, regulatórias ou fiscais, envolvendo a
                divulgação de seus dados pessoais a terceiros, a um tribunal,
                reguladores ou agências governamentais.
            </p>

            <h3>ONDE ARMAZENAMOS E PROCESSAMOS SEUS DADOS</h3>
            <p>Os dados coletados são armazenados e processados no servidor em Nuvem da AWS, para fins de melhorar o desempenho e proteger os dados no caso de uma falha ou outro problema.</p>

            <h3>RESPONSABILIDADES DO CONTROLADOR E MEDIDAS DE SEGURANÇA</h3>
            <p>
                Todos os dados que você nos fornece são tratados unicamente para
                atingir as finalidades acima listadas. Estes dados são
                armazenados em servidores seguros nossos ou de fornecedores
                contratados, acessados e utilizados de acordo com nossas
                políticas e padrões de segurança. Todo o tráfego entre
                nossos servidores ou entre o seu computador e nossos servidores
                é encriptado através do protocolo seguro SSL ou semelhante.
                Tomamos medidas de boas práticas e certificações existentes
                no mercado para garantir que os dados que coletamos sejam
                processados de acordo com segurança onde quer que os dados estejam localizados.
            </p>

            <h3>QUANTO TEMPO VAMOS ARMAZENAR SUAS INFORMAÇÕES</h3>
            <p>
                Nós manteremos as informações que coletamos de você até que ocorra a
                solicitação de exclusão definitiva por sua parte. Neste caso,
                nós cessaremos imediatamente a utilização dos seus dados para fins
                comerciais, porém armazenaremos os seus dados enquanto tenhamos
                obrigações legais, tributárias ou judiciais a cumprir com tais dados.
                DADOS DO ENCARREGADO PELOS DADOS
                A gestão dos seus dados é feita por nós, e você pode entrar em
                contato com o Encarregado pelos seus dados através do e-mail:
                ti@tems.com.br ou faleconosco@tems.com.br. Você também tem o
                direito de contatar diretamente a Autoridade de Proteção de Dados brasileira.
                DIREITOS DO TITULAR DOS DADOS
                O titular dos dados pessoais tem direito a obter do controlador, em relação aos dados do titular por ele tratados, a qualquer momento e mediante requisição:

                <ol type='I'>
                    <li>confirmação da existência de tratamento;</li>
                    <li>acesso aos dados;</li>
                    <li>correção de dados incompletos, inexatos ou desatualizados;</li>
                    <li>anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto nesta Lei;</li>
                    <li>portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;</li>
                    <li>eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas hipóteses previstas no art. 16 da LGPD;</li>
                    <li>informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;</li>
                    <li>informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</li>
                    <li>revogação do consentimento, nos termos do § 5º do art. 8º da LGPD.</li>
                </ol>
            </p>

            <h3>COMO ACESSAR E CONTROLAR SUAS INFORMAÇÕES PESSOAIS</h3>
            <p>
                Você pode solicitar informações, alteração, esclarecimentos ou
                exclusão de seus dados por meio do contato ti@tems.com.br ou
                faleconosco@tems.com.br. Vamos exercer imediatamente as solicitações,
                nos termos da lei de proteção de dados aplicável.
            </p>

            <h3>COMO ENTRAR EM CONTATO CONOSCO</h3>
            <p>
                E-mail: ti@tems.com.br ou faleconosco@tems.com.br
                Mensagem: MEUS DADOS
                Telefone: +55 (11) 3354-1500 ou +55 (11) 99811-7698
                Endereço: Rua Jaraguá, 371 – Bom Retiro – SP/São Paulo
            </p>

            <h4>Política Privacidade</h4>
            <p>A sua privacidade é importante para nós. É política do TEMS respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar no site TEMS, e outros sites que possuímos e operamos.
                Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado.
                Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis ​​para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.
                Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.
                O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.
                Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.
                O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contato conosco.
            </p>

            <h4>Política de Cookies</h4>
            <h5>O que são cookies?</h5>
            <p>Como é prática comum em quase todos os sites profissionais, este site usa cookies,
                que são pequenos arquivos baixados no seu computador,
                para melhorar sua experiência. Esta página descreve quais
                informações eles coletam, como as usamos e por que às vezes
                precisamos armazenar esses cookies. Também compartilharemos
                como você pode impedir que esses cookies sejam armazenados,
                no entanto, isso pode fazer o downgrade ou ‘quebrar’ certos
                elementos da funcionalidade do site.</p>

            <h5>Como usamos os cookies?</h5>
            <p>Utilizamos cookies por vários motivos, detalhados abaixo.
                Infelizmente, na maioria dos casos, não existem opções
                padrão do setor para desativar os cookies sem desativar
                completamente a funcionalidade e os recursos que eles
                adicionam a este site. É recomendável que você deixe
                todos os cookies se não tiver certeza se precisa ou
                não deles, caso sejam usados ​​para fornecer um serviço que você usa.</p>

            <h5>Desativar cookies</h5>
            <p>Você pode impedir a configuração de cookies ajustando as
                configurações do seu navegador (consulte a Ajuda do navegador
                para saber como fazer isso). Esteja ciente de que a
                desativação de cookies afetará a funcionalidade deste e
                de muitos outros sites que você visita. A desativação
                de cookies geralmente resultará na desativação de determinadas
                funcionalidades e recursos deste site. Portanto, é recomendável
                que você não desative os cookies.</p>

            <h4>Cookies que definimos</h4>
            <ul>
                <li>Cookies relacionados à conta <br /><br />Se você criar uma conta conosco, usaremos cookies para o gerenciamento do processo de inscrição e administração geral. Esses cookies geralmente serão excluídos quando você sair do sistema, porém, em alguns casos, eles poderão permanecer posteriormente para lembrar as preferências do seu site ao sair</li>
                <li>Cookies relacionados ao login <br /><br />Utilizamos cookies quando você está logado, para que possamos lembrar dessa ação. Isso evita que você precise fazer login sempre que visitar uma nova página. Esses cookies são normalmente removidos ou limpos quando você efetua logout para garantir que você possa acessar apenas a recursos e áreas restritas ao efetuar login.</li>
                <li>Cookies relacionados a boletins por e-mail <br /><br />Este site oferece serviços de assinatura de boletim informativo ou e-mail e os cookies podem ser usados ​​para lembrar se você já está registrado e se deve mostrar determinadas notificações válidas apenas para usuários inscritos / não inscritos.</li>
                <li>Pedidos processando cookies relacionados <br /><br />Este site oferece facilidades de comércio eletrônico ou pagamento e alguns cookies são essenciais para garantir que seu pedido seja lembrado entre as páginas, para que possamos processá-lo adequadamente.</li>
                <li>Cookies relacionados a pesquisas <br /><br />Periodicamente, oferecemos pesquisas e questionários para fornecer informações interessantes, ferramentas úteis ou para entender nossa base de usuários com mais precisão. Essas pesquisas podem usar cookies para lembrar quem já participou numa pesquisa ou para fornecer resultados precisos após a alteração das páginas.</li>
                <li>Cookies relacionados a formulários <br /><br />Quando você envia dados por meio de um formulário como os encontrados nas páginas de contato ou nos formulários de comentários, os cookies podem ser configurados para lembrar os detalhes do usuário para correspondência futura.</li>
                <li>Cookies de preferências do site <br /><br />Para proporcionar uma ótima experiência neste site, fornecemos a funcionalidade para definir suas preferências de como esse site é executado quando você o usa. Para lembrar suas preferências, precisamos definir cookies para que essas informações possam ser chamadas sempre que você interagir com uma página for afetada por suas preferências.</li>
            </ul>

            <h4>Cookies de Terceiros</h4>
            <p>Em alguns casos especiais, também usamos cookies fornecidos por terceiros confiáveis. A seção a seguir detalha quais cookies de terceiros você pode encontrar através deste site.</p>
            <ul>
                <li>Este site usa o Google Analytics, que é uma das soluções de análise mais difundidas e confiáveis ​​da Web, para nos ajudar a entender como você usa o site e como podemos melhorar sua experiência. Esses cookies podem rastrear itens como quanto tempo você gasta no site e as páginas visitadas, para que possamos continuar produzindo conteúdo atraente.</li>
            </ul>
            <p>Para mais informações sobre cookies do Google Analytics, consulte a página oficial do Google Analytics.</p>
            <ul>
                <li>As análises de terceiros são usadas para rastrear e medir o uso deste site, para que possamos continuar produzindo conteúdo atrativo. Esses cookies podem rastrear itens como o tempo que você passa no site ou as páginas visitadas, o que nos ajuda a entender como podemos melhorar o site para você</li>
                <li>Periodicamente, testamos novos recursos e fazemos alterações subtis na maneira como o site se apresenta. Quando ainda estamos testando novos recursos, esses cookies podem ser usados ​​para garantir que você receba uma experiência consistente enquanto estiver no site, enquanto entendemos quais otimizações os nossos usuários mais apreciam.</li>
                <li>À medida que vendemos produtos, é importante entendermos as estatísticas sobre quantos visitantes de nosso site realmente compram e, portanto, esse é o tipo de dados que esses cookies rastrearão. Isso é importante para você, pois significa que podemos fazer previsões de negócios com precisão que nos permitem analizar nossos custos de publicidade e produtos para garantir o melhor preço possível.</li>
            </ul>

            <h4>Compromisso do Usuário</h4>
            <p>O usuário se compromete a fazer uso adequado dos conteúdos e da informação que o TEMS oferece no site e com caráter enunciativo, mas não limitativo:</p>
            <ul>
                <li>A) Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;</li>
                <li>B) Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, ou apostas desportivas (ex.: Betano), jogos de sorte e azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;</li>
                <li>C) Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do TEMS, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados.</li>

            </ul>
        </div>
    );
}
