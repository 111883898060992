import Calendar from "react-calendar"
import { useState, useEffect } from "react"
import {
  useFetchAtendimentosByCityIdQuery
} from "../../../store/index"
import Popup from "components/ui/Popup/Popup"
import RightArrowImg from 'assets/right.png'
import LeftArrowImg from 'assets/left.png';
import { isMobile } from "react-device-detect";

export default function AtendimentoSaoPaulo() {

  useEffect(() => {
    document.title = 'Atendimento São Paulo';
  }, []);

  const [value, onChange] = useState(new Date());
  const months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio",
    "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro",];

  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [popupTitle, setPopupTitle] = useState('');

  const { data, error, isFetching } = useFetchAtendimentosByCityIdQuery(2);

  let content;

  if (error || isFetching) {
    content = <div>Loading...</div>;
  } else if (data) {
    const events = data.data.attributes.atendimentos.data;
    const cityName = data.data.attributes.nome;

    const handleDayClick = (atendimento, titulo) => {
      const content = (
        <>
          <p><span>{atendimento.titulo}</span></p>
          <p><span>Horário</span> {atendimento.horario}</p>
          {atendimento.senha && <p><span>Senha</span> {atendimento.senha}</p>}
          <p><span>Endereço</span> {atendimento.endereco}</p>
          <p><span>Contato</span> {atendimento.Contato}</p>
        </>
      );
      setShowPopup(true);
      setPopupContent(content);
      setPopupTitle(titulo);
    };

    const tileContent = ({ date }) => {
      const eventsArray = events.filter(event => {

        const formatedDate = event.attributes.data.replace(/-/g, "/");

        const eventDate = new Date(formatedDate);

        return eventDate.getTime() === date.getTime();
      });



      if (eventsArray) {
        return (
          <div className="atendimento-saopaulo__event-box">
            {eventsArray.map((event) => {
              let atendimento = event.attributes;
              let dataAtendimento = new Date(atendimento.data + 'T00:00:00');

              let className = "atendimento-saopaulo__event--";
              className += atendimento.tipo ? atendimento.tipo.replace(/\s+/g, '-') : 'atendimento';

              return (
                <p
                  key={event.id}
                  className={"atendimento-saopaulo__event " + className}
                  onClick={() => handleDayClick(atendimento, `Dia ${dataAtendimento.getDate()}  ${atendimento.horario}`)}
                >
                  {atendimento.titulo}
                </p>
              );
            }

            )}
          </div>
        );
      }
    };


    content = (
      <>
        <h3 className="atendimento-saopaulo__name">Atendimento em {cityName}</h3>
        <div className="atendimento-saopaulo__box">
          <div className="atendimento-saopaulo__next-month" onClick={() => {
            if (value.getMonth() === new Date().getMonth()) {
              const newDate = new Date();
              newDate.setMonth(newDate.getMonth() + 1);
              onChange(newDate);
            } else {
              onChange(new Date());
            }
          }}>
            <p>{value.getMonth() === new Date().getMonth() ? 'próximo mês' : 'mês anterior'}</p>
            <div>
              <img src={value.getMonth() === new Date().getMonth() ? RightArrowImg : LeftArrowImg} />
            </div>
          </div>
          <h4 className="atendimento-saopaulo__data">
            {months[value.getMonth()]} | {value.getFullYear()}
          </h4>
          <Calendar
            activeStartDate={value}
            tileContent={tileContent}
            onChange={onChange}
            value={value}
            defaultView="month"
            locale="pt-BR"
            formatDay={CustomWeekDay}
            formatShortWeekday={(locale, date) => ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'][date.getDay()]}
          />
          {showPopup && <Popup content={popupContent} title={popupTitle} onClose={() => setShowPopup(false)} />}
        </div>
      </>
    );
  }

  return <div className="atendimento-saopaulo__container">{content}</div>;
}


const CustomWeekDay = (locale, date) => {

  const day = new Intl.DateTimeFormat(locale, {
    day: '2-digit'
  }).format(date).replace(/^0+/, '');

  const weekDay = new Intl.DateTimeFormat(locale, {
    weekday: 'short'
  }).format(date);

  return (
    <div>
      {day}
      {isMobile && <span className="atendimento-saopaulo__weekday">{weekDay.replace(/[\s.]+/g, '')}</span>}
    </div>
  );
}