import closeIcon from 'assets/close.png'

function Popup({ content, title, onClose }) {
    return (
        <div className='popup'>
            <div className="popup__box">
                <div className="popup__title">{title}
                    <button className="popup__close" onClick={onClose}><img src={closeIcon} /></button>
                </div>
                <div className="popup__content">{content}</div>
            </div>
        </div>
    );
}

export default Popup;