import Air from 'assets/icones-algodao/air.svg';
import Fire from 'assets/icones-algodao/fire.svg';
import Water from 'assets/icones-algodao/water.svg';
import Plant from 'assets/icones-algodao/plant.svg';
import Arrow from 'assets/icones-algodao/ep_arrow-up-bold.png';

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";


import SwiperSliderButtonPrev from "../../components/ui/Swiper/SwiperStoreButtonPrev";
import SwiperSliderButtonNext from "../../components/ui/Swiper/SwiperStoreButtonNext";

import Slide1 from 'assets/algodao_slide.png';
import Slide2 from 'assets/algodao_slide_2.png';
import Slide3 from 'assets/algodao_slide_3.png';
import Slide4 from 'assets/algodao_slide_4.png';
import { useState, useRef, useEffect } from 'react';
import axios from 'axios';


export default function CerimoniaAlgodaoPage() {

  const resultRef = useRef(null);
  const [mensagem, setMensagem] = useState('');
  const [envioHabilitado, setEnvioHabilitado] = useState(false);

  const [dados, setDados] = useState({
    name: '',
    email: '',
    names: '',
    acceptedTerms: false
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await axios.post(process.env.REACT_APP_GOOGLESHEETS_URL + 'algodao', {
        nome: dados.name,
        email: dados.email,
        conteudo: dados.names.replace(/, /g, ',').replace(/,/g, '\n')
      });

      setMensagem('Dados  enviados com sucesso!');
      clear();
    } catch (error) {
      setMensagem('Não foi possível enviar os dados.');
    }

    clear();
  };

  const clear = () => {
    setDados({
      name: '',
      email: '',
      names: '',
      acceptedTerms: false
    });
  }

  useEffect(() => {
    const todosCamposPreenchidos = dados.name !== '' && dados.email !== '' && dados.names !== '';
    const termosAceitos = dados.acceptedTerms;
    setEnvioHabilitado(todosCamposPreenchidos && termosAceitos);
  }, [dados]);

  const handleChange = (name, value) => {
    setDados({
      ...dados,
      [name]: value,
    });
  };

  return (
    <div className='algodao'>
      <section className='algodao__header'>
        <div className="algodao__header-text-box">
          <h2 className="algodao__header-title">Cerimônia do Algodão</h2>
          <p className="algodao__header-text">Por meio de um algodão com óleo de mirra, é possível
            realizar uma <strong>limpeza dos miasmas</strong> presentes no seu campo
            áurico, dos seus familiares e amigos encarnados, trabalhando
            a cura do corpo e da alma.</p>

          <span className="algodao__header-saibamais" onClick={() => {
            resultRef.current.scrollIntoView({ behavior: "smooth" })
          }}>Saber mais <img src={Arrow} /></span>
        </div>

        <form className="algodao__form" onSubmit={handleSubmit}>
          <div className="algodao__input-box">
            <label className="">Nome Completo</label>
            <input className="" type='text' value={dados.name} onChange={(event) => handleChange('name', event.target.value)} />
          </div>

          <div className="algodao__input-box">
            <label className="">Email</label>
            <input className="" type='email' value={dados.email} onChange={(event) => handleChange('email', event.target.value)} />
          </div>

          <div className="algodao__input-box">
            <label className="">Nomes para Cerimônia</label>
            <textarea placeholder='Escreva os nomes separados por vírgula...' value={dados.names} onChange={(event) => handleChange('names', event.target.value)} />
            {mensagem && <div style={{marginTop: '10px'}}>{mensagem}</div>}
          </div>
          <div className="algodao__input-checkbox">
            <input className="" type='checkbox' checked={dados.acceptedTerms} onChange={(event) => handleChange('acceptedTerms', event.target.checked)} />
            Eu aceito os termos da Política de Privacidade
          </div>

          <button className="algodao__button" disabled={!envioHabilitado}>Enviar nomes</button>
        </form>
      </section>

      <section className="algodao__sobre" ref={resultRef}>
        <h5>O que é?</h5>
        <p>
          A Cerimônia do Algodão é um ritual realizado pela Cigana Espiritual Esmeralda,
          através da mediunidade de Renata Nunes, toda sexta-feira que antecede a Reunião
          com o Irmão Espiritual Bezerra de Menezes para auxiliar na limpeza dos miasmas
          presentes no campo áurico. Por meio de um algodão com gotas de óleo de mirra,
          é realizada uma absorção dessas “sujeiras espirituais” para que possam dissolver
          as energias ruins do organismo, trabalhando a cura do corpo e da alma.<br /><br />Explica o
          Cigano Espiritual Juan Dominguez:<br />
          <span style={{ color: '#005b00', fontWeight: '600', fontStyle: 'italic' }}>“O trabalho feito aqui é para retirar essas
            doenças invisíveis, imperceptíveis, mas que atuam fortemente para atrapalhar o seu
            desenvolvimento. Quando é feito este trabalho, o mal é transportado para o algodão.
            Este é o recipiente que irá conduzir este mal a um local preparado para ser
            incinerado, com isso, eliminando-o totalmente da vida de vocês.”</span><br /><br />
          Cada um recebe um algodão com óleo de mirra, pelas mãos da Cigana Espiritual Esmeralda,
          que deve ser aplicado na região enferma. Caso não tenha nenhuma enfermidade,
          indica-se que o algodão seja aplicado em sua fronte e no cardíaco para tirar
          aquilo que ainda não está manifestado no corpo físico.
        </p>

        <Swiper
          modules={[Navigation, Autoplay, A11y]}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          spaceBetween={30}
          slidesPerView={1}
          loop={true}
          navigation={{
            nextEl: '.loja-tems__arrow-right',
            prevEl: '.loja-tems__arrow-left',
          }}
        >
          <SwiperSliderButtonPrev />
          <SwiperSliderButtonNext />

          <SwiperSlide>
            <img className='algodao__sobre-slide' src={Slide1} />
          </SwiperSlide>

          <SwiperSlide>
            <img className='algodao__sobre-slide' src={Slide2} />
          </SwiperSlide>

          <SwiperSlide>
            <img className='algodao__sobre-slide' src={Slide3} />
          </SwiperSlide>

          <SwiperSlide>
            <img className='algodao__sobre-slide' src={Slide4} />
          </SwiperSlide>

        </Swiper>

      </section>

      <section className='algodao__como-funciona'>
        <h5>Como funciona?</h5>
        <p>São trabalhados os 4 elementos da natureza</p>

        <ul>
          <li>
            <div className='algodao__icons-box'>
              <img src={Air} />
            </div>
            <div>
              <h6>O ar</h6>
              <p>por meio do sopro, a Cigana Esmeralda ativa as energias no algodão</p>
            </div>
          </li>
          <li>
            <div className='algodao__icons-box'>
              <img src={Plant} />
            </div>
            <div>
              <h6>A terra</h6>
              <p>representada pelo algodão, que é o condutor dessas energias</p>
            </div>
          </li>
          <li>
            <div className='algodao__icons-box'>
              <img src={Water} />
            </div>
            <div>
              <h6>A água</h6>
              <p>representada pelo óleo de mirra</p>
            </div>
          </li>
          <li>
            <div className='algodao__icons-box'>
              <img src={Fire} />
            </div>
            <div>
              <h6>O fogo</h6>
              <p>é realizada a queima desses algodões, purificando e eliminando esses miasmas</p>
            </div>
          </li>
        </ul>

        <p>
          <span>“Um algodão junto com mirra, tal qual Jesus recebeu para poder ungir a cada um de vós;
            assim como Jesus, um dia na Terra, também se colocou como ungido de Deus para
            poder nos ajudar. O algodão, como também é utilizado por Bezerra de Menezes para
            poder curar a todos nós, preparando os caminhos, para que a nossa alma esteja mais
            aberta a ouvir, a aprender, a sentir a energia de Nosso Senhor.”<br />Mensagem do
            Cigano Espiritual Julio del Toro</span><br /><br />Assim como Jesus conseguia curar os enfermos por
          meio da fé e do amor, são trazidas pelos Ciganos Espirituais todas as condições
          para a cura dos males.
        </p>
      </section>

      <section className='algodao__sobre algodao__participar'>
        <h5>Consigo participar a distância?</h5>
        <p>
          É possível participar desta cerimônia sem estar presente no local,
          sintonizando-se em nosso canal no <a href='https://www.youtube.com/channel/UCBeAZFzkC8MBWN_DEIC8EqQ' target='_blank' style={{color: '#002a89', fontWeight: 'bold'}}>YOUTUBE</a> a partir das 9h30 do dia da cerimônia.
          A equipe espiritual que atua no TEMS irá até você recolhendo todos os miasmas
          que bloqueiam o seu bem-estar. É importante que esteja na sintonia para criar
          condições para que essas equipes espirituais possam adentrar seu lar e te auxiliar.
          A espiritualidade indica que se coloque um copo com água para ser tomado
          após a cerimônia.<br /><span style={{ color: '#005b00', fontWeight: '600' }}>A Cigana Espiritual Esmeralda ressalta que:</span><br />
          <span style={{ color: '#005b00', fontWeight: '600', fontStyle: 'italic' }}>“Este trabalho é para que possamos ajudar na energia de todas as pessoas que
            virão para receber esse ilustre Espírito de Luz que vem para ajudar a caminhada,
            a jornada de todos os que aqui estão, de todos os que necessitam se encontrar
            com Deus, se encontrar com o Seu amor, o amor do Cristo.”</span><br /><br />Participe de mais um
          trabalho realizado pelo Templo Espiritual Maria Santíssima. Deixe os nomes de
          seus entes queridos até a quinta-feira que antecede a Cerimônia, às 20h,
          e fique sintonizado em nossa programação.<br />*Cerimônia destinada somente para
          encarnados.
        </p>
      </section>

      <p className='algodao__adquira-itens'>Adquira os itens para a Cerimônia do Algodão <span style={{ fontSize: '1.7rem', fontWeight: '500', marginLeft: '1rem' }}><a href='https://temsonline.com.br/produto/oleo-perfumado-de-mirra/'>Clique aqui</a> e adquira seu Óleo de Mirra</span></p>
    </div>
  )
}
